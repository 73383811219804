import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TxnScoreLine = ({ txn }) =>
  txn && txn.score ? (
    <span className='score-line'>
      <span className={classnames({ grade: true, [txn.score.grade]: true })}>
        {txn.score.grade && txn.score.grade.replaceAll('_', ' ')}
      </span>{' '}
      <span className='result'>
        ({txn.score.result}/{txn.score.max})
      </span>
    </span>
  ) : (
    <span className='score-line none'>-</span>
  )

TxnScoreLine.propTypes = {
  txn: PropTypes.object,
}

export default TxnScoreLine
