import React from 'react'
import classnames from 'classnames'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

const UserDateTime = ({ at }) => {
    if(at) {
        const now = DateTime.local()
        const dt = at ? DateTime.fromISO(at) : null
        const isToday = dt.hasSame(now, 'day')
        const isSameHour = isToday && dt.hasSame(now, 'hour')
        return (
            <span className={classnames({'date-time': true, today: isToday, 'this-hour': isSameHour})}>
                <span className='date'> {dt.toISODate()}</span>{' '}
                <strong className='time'>{dt.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET)}</strong>
            </span>
        )
    }

    return (<span></span>)

}

UserDateTime.propTypes = {
  at: PropTypes.string,
}

export default UserDateTime
