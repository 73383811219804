import * as XLSX from 'xlsx'
import { useEffect, useState } from 'react'

const TopRouteExcelDownload = ({ data }) => {
  let excelData = []
  const [sheetData, setSheetdata] = useState([])

  const formatData = () => {
    excelData = []
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        let object = {
          rule: value?.rule,
          sum_Requested_Amount_EUR:
            '€' +
            Math.trunc(value.sum_Settled_Amount_EUR)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          spend:
            '€' +
            Math.trunc(value.total)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        }
        value?.mid_acceptance_rates
          ?.sort((a, b) => a.mid.localeCompare(b.mid))
          .forEach((sortedItem) => {
            if (sortedItem.acceptance_rate == null) {
              object[sortedItem.mid] = ''
            } else {
              object[sortedItem.mid] =
                (sortedItem.acceptance_rate * 100).toFixed(1).toString() + '%'
            }
          })
        excelData.push(object)
      }
      setSheetdata(excelData)
    }
  }

  const handleExport = () => {
    var wb = XLSX.utils.book_new()
    var ws = XLSX.utils.json_to_sheet(sheetData)
    XLSX.utils.sheet_add_aoa(ws, [['RULE', 'Requested Volume', 'Spend (EUR)']], { origin: 'A1' })
    XLSX.utils.book_append_sheet(wb, ws, 'Top Routes Summary')
    XLSX.writeFile(wb, 'TopRoutesSummary.xlsx')
  }

  useEffect(() => {
    setSheetdata([])
    formatData()
  }, [data])

  return (
    <div className='excel-download-container'>
      <button onClick={() => handleExport()}>
        <i className='fas fa-file-excel' style={{ margin: 'auto' }}></i>
      </button>
    </div>
  )
}

export default TopRouteExcelDownload
