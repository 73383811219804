import Header from './Header'
import Footer from './Footer'
import Helmet from 'react-helmet'
import { useTheme } from './theme'

const Support = () => {
  const theme = useTheme()
  return (
    <section className='support'>
      <Header />
      <Helmet>
        <title>Contact support</title>
      </Helmet>
      <div className='content'>
        <div>
          <h1>Contact support</h1>
          <p>
            Please contact <a href={`mailto:${theme.supportEmail}`}>{theme.supportEmail}</a> for
            assistance or queries.
          </p>
          <p>
            If you are an existing client, please ensure that we add you to your own dedicated{' '}
            <strong>Slack channel</strong> for instant access to our support staff.
          </p>
          <h1>API Reference</h1>
          <p>
            If you or your team are busy integrating with {theme.name}, you may find the API
            reference at:
          </p>
          <p>
            <a href={theme.apiDocsUrl} target='_blank' rel='noreferrer'>
              API docs
            </a>
          </p>
          {/* TODO: If user is authenticated, link directly to their slack channel. */}
          {/* TODO: Support widget like intercom? */}
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Support
