import PropTypes from 'prop-types'
import classnames from 'classnames'
import { majorErrorCodes, minorErrorCodes } from './error-codes'

export const statusNames = {
  ok: 'ok',
  failed: 'failed',
  pending: 'pending',
  cust_verification_required: '3ds pending',
  verification_3ds: '3ds pending',
  verification_kyc: 'kyc pending',
  charged_back: 'charged back',
}

/** Renders a status name for human consumption */
export const renderStatusName = (s, source, code) => {
  if (s === 'cust_verification_required' && source === 2) {
    return 'kyc pending'
  }
  if (s === 'failed' && code == 22) {
    return 'abandoned'
  }

  return statusNames[s] || (s && s.replaceAll('_', ' ')) || '-'
}

/**
 *  TxnStatus renders a small badge with a given status, and possibly including interactive help
 * etc to understand this status.
 */
const TxnStatus = ({
  status,
  failure_code,
  failure_code_minor,
  className = 'status',
  cust_verification_required = {},
}) => (
  <span
    className={classnames({
      [className]: true,
      [status]: status,
      [`source_${cust_verification_required?.source}`]: true,
    })}
  >
    <span className='name'>
      {renderStatusName(status, cust_verification_required?.source, failure_code)}
    </span>
    {status == 'abandoned' && failure_code_minor && (
      <span className='codes'>
        {' '}
        <FailureCodeMinor code={failure_code} minorCode={failure_code_minor} />
      </span>
    )}
    {status == 'failed' && (
      <span className='codes'>
        {status == 'failed' && failure_code && (
          <>
            {' '}
            <FailureCode code={failure_code} />
          </>
        )}
        {status == 'failed' && failure_code && failure_code_minor && (
          <>
            {' '}
            <FailureCodeMinor code={failure_code} minorCode={failure_code_minor} />
          </>
        )}
      </span>
    )}
  </span>
)
TxnStatus.propTypes = {
  status: PropTypes.string,
  failure_code: PropTypes.number,
  failure_code_minor: PropTypes.number,
  className: PropTypes.string,
}

const FailureCode = ({ code }) => (
  <span
    title={majorErrorCodes.get(code) ? majorErrorCodes.get(code) : 'Failure code'}
    className={classnames({ code: true, [code]: true })}
  >
    {code}
  </span>
)

FailureCode.propTypes = {
  code: PropTypes.number,
}

const FailureCodeMinor = ({ code, minorCode }) => {
  const minorsForMajor = minorErrorCodes.get(code)
  const title = minorsForMajor
    ? minorsForMajor.get(minorCode)
      ? minorsForMajor.get(minorCode)
      : 'Minor code'
    : 'Minor code'
  return (
    <span title={title} className={classnames({ 'minor-code': true, [minorCode]: true })}>
      {minorCode}
    </span>
  )
}
FailureCodeMinor.propTypes = {
  code: PropTypes.number,
  minorCode: PropTypes.number,
}

export default TxnStatus
