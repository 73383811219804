import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  pathOr,
  values,
  propOr,
  prop,
  sortBy,
  compose,
  toLower,
  includes,
  propEq,
  filter as rFilter,
} from 'ramda'

/**
 * MerchantFilter lets the user pick one or more merchants.
 * todo this a work in progress and should eventually handle contracts as well as merchants
 */
const MerchantFilter = ({ filter, setFilter, orgsByID = {} }) => {
  const availableMerchants =
    sortBy(
      compose(toLower, propOr(prop('id'), 'name')),
      compose(rFilter(propEq('type', 'merchant')), values)(orgsByID)
    ) || []
  const availableContracts =
    sortBy(
      compose(toLower, propOr(prop('id'), 'name')),
      compose(rFilter(propEq('type', 'contract')), values)(orgsByID)
    ) || []
  let selected = filter.merchant_any || []
  let contractSelected = false
  if (filter.contract_id) {
    selected = [filter.contract_id]
    contractSelected = true
  }

  // The sequence of merchant IDs driving the UI (one per line)
  const uiseq = selected && selected.length > 0 ? selected : ['']

  const onChangeAtIndex = (index) => (e) => {
    if (e.target.value == '') {
      setFilter({ ...filter, contract_id: null, merchant_any: [] })
    } else if (availableContracts?.find((x) => x.id == e.target.value)) {
      setFilter({ ...filter, contract_id: e.target.value, merchant_any: [] })
    } else {
      const newSel = [...filter.merchant_any]
      newSel[index] = e.target.value
      setFilter({ ...filter, contract_id: null, merchant_any: newSel })
    }
  }

  const onRemoveAtIndex = (index) => () => {
    setFilter({
      ...filter,
      merchant_any: [
        ...filter.merchant_any.slice(0, index),
        ...filter.merchant_any.slice(index + 1),
      ],
    })
  }

  const onAddOption = () => {
    setFilter({ ...filter, merchant_any: [...filter.merchant_any, ''] })
  }

  return (
    <div className='filter merchants'>
      {uiseq.map((sel, i) => {
        // If this is the last item, produce a set of available options that
        // excludes any selected options already. Note: Expected for selected options
        // to always be small, hence inefficient list traversal.
        const availableGivenSelected = [...availableMerchants].filter(
          ({ id }) => id == sel || !includes(id, selected)
        )
        return (
          <div
            className={classnames({ 'merchant-option': true, unchosen: !sel && uiseq.length > 1 })}
            key={sel}
          >
            {i > 0 && <span className='or'>or</span>}
            {/* Only the last option is editable */}
            {i == uiseq.length - 1 ? (
              <select
                value={sel}
                onChange={onChangeAtIndex(i)}
                disabled={availableMerchants.length <= 1 && availableContracts.length <= 1}
              >
                {availableMerchants.length == 0 && <option value=''>All Transactions</option>}
                {availableContracts.length > 1 && (
                  <optgroup label='Contracts'>
                    {availableContracts?.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.name}
                      </option>
                    ))}
                  </optgroup>
                )}
                {availableMerchants.length > 0 && (
                  <optgroup label='Merchants'>
                    {selected.length <= 1 && <option value=''>All Merchants</option>}
                    {availableGivenSelected.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.name}
                      </option>
                    ))}
                  </optgroup>
                )}
              </select>
            ) : (
              <span className='selected'>{pathOr(sel, [sel, 'name'], orgsByID)}</span>
            )}
            {/* If there are > 1 selected options, any can be removed */}
            {selected.length > 1 && (
              <button
                className='remove'
                onClick={onRemoveAtIndex(i)}
                title='Remove this merchant from selection'
              >
                <i className='fas fa-times' />
              </button>
            )}
            {/* If the last option is defined, button to add more */}
            {i === uiseq.length - 1 && !contractSelected && sel && (
              <button
                className='add'
                onClick={onAddOption}
                title='Add another merchant to your selection'
              >
                <i className='fas fa-plus' />
              </button>
            )}
          </div>
        )
      })}
    </div>
  )
}

MerchantFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  orgsByID: PropTypes.object.isRequired,
}

export default MerchantFilter
