import PropTypes from 'prop-types'
import { useAuth } from '../auth'
import { getRefundsCSVURL } from '../api'

/** Renders a widget that, given a `filter` specification, allows the current user to download
 * refunds matching the filter, using the user's auth token. */
const DownloadCSVLink = ( {filter, displayCount} ) => {
  const { token } = useAuth()
  let csvURL = getRefundsCSVURL(token, displayCount,filter)
  if (displayCount === 0) {
    return null
  }
  return (
    <span className='download-csv'>
      <a href={csvURL} title='Download all transactions matching the current filter as CSV'>
        Download {displayCount != null && displayCount.toLocaleString()} as CSV
      </a>
    </span>
  )
}
DownloadCSVLink.propTypes = {
  filter: PropTypes.string,
  displayCount: PropTypes.number,
}
export default DownloadCSVLink
