import React from 'react'
import Header from './Header'
import Helmet from 'react-helmet'
import { useAuth } from './auth'
import { Redirect } from 'react-router-dom'

const SignOut = () => {
  const { setToken } = useAuth()
  // Kill user's active token
  setToken(null)
  return (
    <section className='sign-out'>
      <Header />
      <Helmet>
        <title>Sign out</title>
      </Helmet>
      <div className='content'>
        <div>
          <h1>You are now signed out</h1>
          <Redirect to='/' />
        </div>
      </div>
    </section>
  )
}

export default SignOut
