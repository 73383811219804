import { render } from 'react-dom'
import App from './App'

// Enable module hot-reloading for Parcel bundler
if (window.module && window.module.hot) {
  window.module.hot.accept()
}

// Mount the app
render(<App />, document.getElementById('root'))
