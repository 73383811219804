import { updateSearchParamsWithFilters, concretiseParams } from './txns/filters'
import { updateSearchParamsWithPagination } from './page'

// Client-side (i.e. in-browser) API proxy
// to call the Cosmonaut merchant and operator
// back-end. Authentication happens per-call using
// a provided token.
const API_BASE_URL =
  window.location.hostname === 'localhost'
    ? 'http://localhost:8090'
    : `https://${window.location.hostname}`

/** Given a `fetch` response, handles redirecting the
 * user to the sign-in page if server sends a 401 unauthorized,
 * otherwise simply returns the given response. */
const handleSignIn = (response, throwOnError = true) => {
  if (!response.ok) {
    // Trap 401 and route user to login page
    if (response.status === 401) {
      if (console && console.warn) {
        console.warn('Redirecting user to login page because 401 received: ', response)
      }
      // TODO: Should we do this (which could also be useful in triggering
      // a page load, updating e.g. stale app code) or should we use the
      // history API (react-router) ?
      if (window.location.href.indexOf('/sign-in') == -1) {
        // Prevent redirect loop
        window.location.href = '/sign-in'
      }
    }
    // Pass-through any other error
    if (console && console.warn) {
      console.warn('Error response received: ', response)
    }

    if (throwOnError) {
      throw Error(response.statusText)
    }
  }
  return response
}

/** Ping the server to check if it's up. Requires valid token. */
export const ping = (bearerToken) =>
  fetch(`${API_BASE_URL}/api/ping`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.text()) // <- Server returns text, not JSON, in this case

/**
 * Retrieve a list of transactions, optionally filtered and paginated
 * using the given query object.
 */
export const getTransactions = (bearerToken, { filter, page }) => {
  // Build parameter set
  const params = new URLSearchParams()
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  // Pagination control
  updateSearchParamsWithPagination(params, page)
  return fetch(`${API_BASE_URL}/api/transactions?${params.toString()}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * Retrieve a list of merchants, optionally filtered and paginated
 * using the given query object.
 */
export const getMerchants = (bearerToken, { filter, page }) => {
  // Build parameter set

  const params = new URLSearchParams()
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  // Pagination control
  updateSearchParamsWithPagination(params, page)

  let apiUrl = `${API_BASE_URL}/api/merchants?${params.toString()}`
  if (!apiUrl.includes('count=')) {
    apiUrl = apiUrl + '&count=1000'
  }
  return fetch(apiUrl, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

export const getAllMerchants = (bearerToken) => {
  return fetch(`${API_BASE_URL}/api/merchants?count=1000`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * Activate a merchant
 */
export const activateMerchant = (bearerToken, merchant_id, reason) => {
  return fetch(`${API_BASE_URL}/api/merchant/activate`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify({
      id: merchant_id,
      reason: reason,
    }),
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * Deactivate a merchant
 */
export const deactivateMerchant = (bearerToken, merchant_id, reason) => {
  return fetch(`${API_BASE_URL}/api/merchant/deactivate`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify({
      id: merchant_id,
      reason: reason,
    }),
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * set a merchants secret
 */
export const setMerchantSecret = (bearerToken, merchant_id, secret) => {
  return fetch(`${API_BASE_URL}/api/merchant/secret`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify({
      id: merchant_id,
      secret: secret,
    }),
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * Retrieve a list of BINS
 * TODO: Replace with real API call
 */
export const getDashboardStats = (bearerToken, { filter, page }) => {
  // Build parameter set
  const params = new URLSearchParams()
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  // Pagination control
  updateSearchParamsWithPagination(params, page)

  return fetch(`${API_BASE_URL}/api/dashboard?${params.toString()}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/*
 * Retrieve a list of contracts
 */
export const getContracts = (bearerToken) => {
  return fetch(`${API_BASE_URL}/api/contracts`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

/** Produces a URL that, when visited by the client, will result in a CSV download
 * of the transactions that the client is allowed to see, filtered by the given
 * filter. */
export const getTransactionsCSVURL = (bearerToken, { filter }) => {
  // Build parameter set
  const params = new URLSearchParams({ auth_token: bearerToken })
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  params.set('format', 'csv')
  return `${API_BASE_URL}/api/transactions?${params.toString()}`
}

export const getKYCCSVUrl = (bearerToken, owner, { filter }) => {
  // Build parameter set
  const params = new URLSearchParams({ auth_token: bearerToken })
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  params.set('format', 'csv')
  return `${API_BASE_URL}/api/kyc/admin/${owner}/list?${params.toString()}`
}

export const getRefundsCSVURL = (bearerToken, count, filter) => {
  // Build parameter set
  const params = new URLSearchParams({ auth_token: bearerToken })
  params.set('format', 'csv')
  params.set('count',count)
  if(filter.length > 0) {
    filter = '&'+filter
  }
  return `${API_BASE_URL}/api/transactions/refunds?${params.toString()}${filter}`
}

/** Retrieve a single transaction. */
export const getTransaction = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/transaction/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** Retrieve a single merchant. */
export const getMerchant = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/merchant/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/**
 * Produces a URL that, when visited by the client, will result in a CSV download
 * of the merchants that the client is allowed to see, filtered by the given filter.
 */
export const getMerchantsCSVURL = (bearerToken, { filter }) => {
  // Build parameter set
  const params = new URLSearchParams({ auth_token: bearerToken })
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  params.set('format', 'csv')
  return `${API_BASE_URL}/api/merchants?${params.toString()}`
}

/** Try and authenticate user and if successful
 * store the token in session storage.
 */
export const authenticateUser = (email, password) =>
  fetch(`${API_BASE_URL}/api/auth`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
    },
    redirect: 'error',
    body: JSON.stringify({
      strategy: 1,
      password,
      id_in_strategy: email,
    }),
  }).then((r) => {
    if (r.status == 200 || r.status == 401) {
      return r.json()
    }
    return null
  })

export const syncTransaction = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/payment/transaction/${id}/sync`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
    },
    redirect: 'error',
  }).then((r) => r.json())

export const initPasswordReset = (c) =>
  fetch(`${API_BASE_URL}/api/auth/reset/init`, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(c),
  }).then((r) => r.json())

export const completePasswordReset = async (c) => {
  const resp = await fetch(`${API_BASE_URL}/api/auth/reset`, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(c),
  })
  if (resp.status === 200 || resp.status === 400) {
    return resp.json()
  } else {
    const errorMessage = await resp.text()
    throw errorMessage
  }
}

export const checkPassword = async (password) => {
  const resp = await fetch(`${API_BASE_URL}/api/auth/password/score`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Accept: 'application/json',
    },
    redirect: 'error',
    body: JSON.stringify({ Password: password }),
  })
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

export const getAccessibleMerchants = async (bearerToken) => {
  const resp = await fetch(`${API_BASE_URL}/api/merchants?count=1000`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
    },
    redirect: 'error',
  })
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

export const getAccessibleContracts = async (bearerToken) => {
  const resp = await fetch(`${API_BASE_URL}/api/contracts`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
    },
    redirect: 'error',
  })
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

export const getUsers = async (bearerToken, { filter, page }) => {
  // Build parameter set
  const params = new URLSearchParams()
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  // Pagination control
  updateSearchParamsWithPagination(params, page)

  const resp = await fetch(`${API_BASE_URL}/api/auth/users?${params.toString()}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
    },
    redirect: 'error',
  })
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

/** Retrieve a single user */
export const getUser = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/auth/user/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** enable a user */
export const enableUser = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/auth/user/${id}/enable`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** disable a user */
export const disableUser = (bearerToken, id) =>
  fetch(`${API_BASE_URL}/api/auth/user/${id}/disable`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/*
 * invite a user
 */
export const inviteUser = (bearerToken, merchantId, email) => {
  return fetch(`${API_BASE_URL}/api/merchant/${merchantId}/users/invites`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify({
      email: email,
    }),
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

export const assignUserRole = (bearerToken, userId, role) => {
  return fetch(`${API_BASE_URL}/api/merchant/users/roles/add`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify({
      user: userId,
      roles: role,
    }),
  }).then((r) => r.json())
}

export const removeUserRole = (bearerToken, userId, role) => {
  return fetch(`${API_BASE_URL}/api/merchant/users/roles/remove`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify({
      user: userId,
      roles: [role],
    }),
  }).then((r) => r.json())
}

export const getPSPs = async (bearerToken) => {
  const resp = await fetch(`${API_BASE_URL}/api/admin/psps`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
    },
    redirect: 'error',
  })
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

export const getPSPMIDs = async (bearerToken, psp_id) => {
  const resp = await fetch(
    `${API_BASE_URL}/api/admin/psp/${psp_id.length == 0 ? '' : psp_id + '/'}mids`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        Accept: 'application/json',
      },
      redirect: 'error',
    }
  )
  if (resp.status === 200) {
    return resp.json()
  }
  throw await resp.text()
}

/*
 * Link a merchant as part of a parent contract
 */
export const linkMerchantAsPartOfParentContract = async (
  bearerToken,
  child_merchant_id,
  parent_contract_id
) => {
  const response = await fetch(
    `${API_BASE_URL}/api/contract/${parent_contract_id}/link/merchant/${child_merchant_id}`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
      redirect: 'error',
    }
  )

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  if (response.status === 400) {
    const error = await response.json()
    if (error && error.code) {
      switch (error.code) {
        case 1:
          throw Error('Unknown contract')
        case 2:
          throw Error('Unknown merchant')
        default:
          throw Error('Internal error')
      }
    }
    throw await error
  }
  throw await response.text()
}

/*
 * Link a merchant as part of a parent merchant
 */
export const linkMerchantAsPartOfAnotherMerchant = async (
  bearerToken,
  child_merchant_id,
  parent_merchant_id
) => {
  const response = await fetch(`${API_BASE_URL}/api/merchant/${child_merchant_id}/part-of`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify(parent_merchant_id),
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  if (response.status === 400) {
    const error = await response.json()
    if (error && error.code) {
      switch (error.code) {
        case 1:
          throw Error('Unknown parent merchant')
        case 2:
          throw Error('Unknown child merchant')
        case 3:
          throw Error('Already linked')
        case 4:
          throw Error('Invalid tree.  Merchant is already a parent')
        case 5:
          throw Error('Invalid tree.  Parent is already a child')
        case 6:
          throw Error('Invalid tree.  Parent must be different from child')
        default:
          throw Error('Internal error')
      }
    }
    throw await error
  }
  throw await response.text()
}

/*
 * UnLink a merchant from a parent merchant
 */
export const unlinkMerchantFromParentMerchant = async (bearerToken, merchant_id) => {
  const response = await fetch(`${API_BASE_URL}/api/merchant/${merchant_id}/part-of`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  if (response.status === 400) {
    const error = await response.json()
    if (error && error.code) {
      switch (error.code) {
        case 1:
          throw Error('Unknown merchant')
        default:
          throw Error('Internal error')
      }
    }
    throw await error
  }
  throw await response.text()
}

/*
 * UnLink a merchant from a parent merchant
 */
export const unlinkMerchantFromContract = async (bearerToken, merchant_id, contract_id) => {
  const response = await fetch(
    `${API_BASE_URL}/api/contract/${contract_id}/unlink/merchant/${merchant_id}`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
      redirect: 'error',
    }
  )

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  if (response.status === 400) {
    const error = await response.json()
    if (error && error.code) {
      switch (error.code) {
        case 1:
          throw Error('Unknown contract')
        case 2:
          throw Error('Unknown merchant')
        default:
          throw Error('Internal error')
      }
    }
    throw await error
  }
  throw await response.text()
}

/*
 * Register a merchant
 */
export const registerMerchant = async (bearerToken, merchant) => {
  const response = await fetch(`${API_BASE_URL}/api/merchants`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify(merchant),
  })

  if (response.status === 401) {
    return handleSignIn(response)
  }
  if (response.status === 200) {
    return response.json()
  }
  if (response.status === 400) {
    const error = await response.json()
    if (error && error.code) {
      switch (error.code) {
        case 1:
          throw Error('Required field is missing')
        case 2:
          throw Error('Input field is malformed')
        case 3:
          throw Error('Parent not found')
        case 4:
          throw Error('Duplicate merchant')
        case 5:
          throw Error('Parent cannot have a child added to it')
      }
    }
    throw await error
  }
  throw await response.text()
}

/** Retrieves the org tree that the caller has access to.
 * This returns a tree of Root -> Contract -> Merchant -> Merchant (brand)
 * of any depth.
 */
export const getMyOrgScopeTree = (bearerToken) =>
  fetch(`${API_BASE_URL}/api/auth/scope/me`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/*
 * Forex rates currently in use
 */
export const getForexRates = (bearerToken) =>
  fetch(`${API_BASE_URL}/api/admin/forex/rates`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/*
 * Forex margins currently in use
 */
export const getForexMargins = (bearerToken) =>
  fetch(`${API_BASE_URL}/api/admin/forex/margins`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** Obtain allow/deny entries for a given scope */
export const queryAllowDenyEntriesForScope = (token, scope, pageCount) =>
  fetch(`${API_BASE_URL}/api/allowdeny/${scope}?count=${pageCount}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** Obtain allow/deny entries for a given scope with filters*/
export const queryFilteredAllowDenyEntriesForScope = (token, scope, filterQuery, pageCount) =>
  fetch(`${API_BASE_URL}/api/allowdeny/${scope}?count=${pageCount}&${filterQuery}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** Post a new allow/deny entry */
export const postAllowDenyEntry = (bearerToken, scope, req) =>
  fetch(`${API_BASE_URL}/api/allowdeny/${scope}/entries`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify(req),
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** Obtain allow/deny entries for a given scope. */
export const deleteAllowDenyEntry = (token, { scope, type, match }) =>
  fetch(
    `${API_BASE_URL}/api/allowdeny/${scope}/entries-by-type/${encodeURIComponent(
      type
    )}/${encodeURIComponent(match)}`,
    {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      redirect: 'error',
    }
  )
    .then(handleSignIn)
    .then(() => ({})) // Note: Service returns no data, for convenience, we return an object

/** Test a given value of a given type, against the allow/deny list(s) for the given scopes.
 * The scopes are IDs, in-order, e.g. [root, contract, merchant, brand] */
export const testAllowDenyValue = (token, { scope = '', type = '', value = '' }) =>
  fetch(
    `${API_BASE_URL}/api/allowdeny/${encodeURIComponent(scope)}/test-by-type/${encodeURIComponent(
      type
    )}/${encodeURIComponent(value)}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      redirect: 'error',
    }
  )
    .then(handleSignIn)
    .then((r) => r.json())

// TODO: Test value against scopes - note: tricky to figure out scopes! (path, e.g. global -> celoxo -> merchA)

/** Retrieves the user using the auth token
 * This returns the user's details
 */
export const getUserByToken = (bearerToken) =>
  fetch(`${API_BASE_URL}/api/auth/user/token`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Accept: 'application/json',
    },
    redirect: 'error',
    body: JSON.stringify({ Token: bearerToken }),
  }).then((r) => (r.status === 200 ? r.json() : r.text()))

/** Queries the server to see if the user's token should be refreshed.
 * Needs to be refreshed if it will expire 2 minutes from now.
 * If so, the user will get a new token that expires again in 15 minutes.
 */
export const refreshUserToken = (bearerToken) =>
  fetch(`${API_BASE_URL}/api/auth/user/token/refresh`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Accept: 'application/json',
    },
    redirect: 'error',
    body: JSON.stringify({ Token: bearerToken }),
  }).then((r) => (r.status === 200 ? r.json() : r.text()))

/** PUT a request to change the status of a transactoin */
export const updateTransactionStatus = (token, { transaction_id, status }) =>
  fetch(`${API_BASE_URL}/api/transaction/${transaction_id}/status/${status}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      status: status,
      // TODO: Optional notes
    }),
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** POST a request to generate a new API token for a merchant */
export const generateMerchantToken = (token, merchant_id, test) => {
  let url = `${API_BASE_URL}/api/merchant/${merchant_id}/tokens`
  if (test) {
    url += `?test=true`
  }
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

export const getKycOwners = (bearerToken) => {
  return fetch(`${API_BASE_URL}/api/kyc/admin/owners`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

export const getKycList = (bearerToken, owner, { /* scope,*/ filter, page }) => {
  // Build parameter set
  const params = new URLSearchParams()
  updateSearchParamsWithFilters(params, filter)
  // Replace any relative ones with absolute ones
  concretiseParams(params)
  // Pagination control
  updateSearchParamsWithPagination(params, page)
  return fetch(`${API_BASE_URL}/api/kyc/admin/${owner}/list?${params.toString()}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

export const getKycProfile = (bearerToken, owner, email) => {
  return fetch(`${API_BASE_URL}/api/kyc/admin/${owner}/email/${email}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

export const uploadKYCIdentity = (bearerToken, owner, email, kycIdentity) => {
  return fetch(`${API_BASE_URL}/api/kyc/admin/${owner}/email/${email}/identity`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify(kycIdentity),
    redirect: 'error',
  }).then((r) => {
    if (r.status === 200) {
      return { success: true }
    } else {
      return { success: false }
    }
  })
}
/*
/**
 * 
 * @param {*} bearerToken 
 * @param {*} email 
 * @returns {{
 * email: string,
 * answered_question_profiles: {
 *  profile: string,
 *  at: string,
 *  source: string,
 *  answers: Object
 * },
 * identity_verifications: {
 *  at: string,
 *  source: string,
 *  detected: Object
 * },
 * updated_at: string
 * }}
 */
export const getKycStatusByEmail = (bearerToken, owner, email) => {
  return fetch(`${API_BASE_URL}/api/kyc/admin/${owner}/email/${email}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

export const shareKYCWithOwner = (bearerToken, owner, email, orgID) => {
  return fetch(`${API_BASE_URL}/api/kyc/admin/${owner}/email/${email}/share/${orgID}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
}

export const getFlexibleRules = (bearerToken, rulesetType) =>
  fetch(`${API_BASE_URL}/api/rules/${rulesetType.toLowerCase()}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

export const setRulesLive = (bearerToken, rulesetType, id) => {
  return fetch(`${API_BASE_URL}/api/rules/${rulesetType.toLowerCase()}/live/${id}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  }).then(handleSignIn)
}

export const getFlexibleRulesById = async (bearerToken, rulesetType, id) => {
  const resp = await fetch(`${API_BASE_URL}/api/rules/${rulesetType.toLowerCase()}/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
  })
  const contentType = resp.headers.get('content-type')
  if (contentType == 'text/plain' && resp.status === 200) {
    const textData = await resp.text()
    return { type: 'grule', text: textData }
  } else if (contentType == 'application/json' && resp.status === 200) {
    const jsonData = await resp.json()
    return { type: 'json', text: jsonData }
  } else {
    const errorMessage = await resp.json()
    throw errorMessage
  }
}

export const addFlexibleRules = async (bearerToken, rulesetType, description, ruleset) => {
  const resp = await fetch(`${API_BASE_URL}/api/rules/${rulesetType.toLowerCase()}/add`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify({
      description: description,
      ruleset: ruleset,
    }),
  })

  if (resp.status === 200) {
    return resp
  } else {
    const errorMessage = await resp.json()
    throw errorMessage
  }
}

export const updateMerchantContact = (bearerToken, emails, id) => {
  return fetch(`${API_BASE_URL}/api/merchant/${id}/update`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify({
      id: id,
      email: emails,
    }),
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())
}

export const putSimulateRoutingRules = (token, rules) =>
  fetch(`${API_BASE_URL}/api/routing/simulation/all`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: rules,
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

export const putRoutingRules = (token, rules) =>
  fetch(`${API_BASE_URL}/api/routing`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: rules,

    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/*
 * submit a merchants style
 */
export const submitMerchantStyle = (bearerToken, merchant_id, styleCustomisation) => {
  return fetch(`${API_BASE_URL}/api/merchant/${merchant_id}/style`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    //JSON.stringify
    body: JSON.stringify({ ...styleCustomisation }),
  }).then((r) => r.json())
}

export const updateMerchantSettings = (
  bearerToken,
  merchant_id,
  hosted_payments_active,
  redirect_flow_active
) => {
  return fetch(`${API_BASE_URL}/api/merchant/${merchant_id}/settings`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    //JSON.stringify
    body: JSON.stringify({
      hosted_payments_active: hosted_payments_active,
      flag_redirect_only_sale: redirect_flow_active,
    }),
  }).then((r) => r.json())
}

export const saveSignicatTheme = (bearerToken, name, themeSettingObject) => {
  return fetch(`${API_BASE_URL}/api/signicat/themes/${name}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
    body: JSON.stringify(themeSettingObject),
  })
    .then(handleSignIn)
    .then((r) => {
      if (r.status === 200) {
        return { success: true }
      } else {
        return { success: false }
      }
    })
}

export const getAllSignicatThemes = (bearerToken) =>
  fetch(`${API_BASE_URL}/api/signicat/themes`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

export const getSignicatTheme = (bearerToken, name) =>
  fetch(`${API_BASE_URL}/api/signicat/themes/${name}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r)

/** POST a request to refund a transaction */
export const refundTransaction = (token, { transaction_id, amount }) =>
  fetch(`${API_BASE_URL}/api/transaction/${transaction_id}/refund`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      amount: amount,
    }),
    redirect: 'error',
  })
    .then((r) => handleSignIn(r, false))
    .then((r) => r.json())

/** POST a request to capture a refund against a transaction */
export const captureRefundForTransaction = (token, { transaction_id, amount,psp_refund_id }) =>
fetch(`${API_BASE_URL}/api/transaction/${transaction_id}/refund/capture`, {
  method: 'POST',
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify({
    amount: amount,
    psp_refund_id:psp_refund_id,
  }),
  redirect: 'error',
})
  .then((r) => handleSignIn(r, false))
  .then((r) => r.json())

/** Get a list of all refunded transactions */
export const getRefundedTransactions = (token, pageCount) =>
  fetch(`${API_BASE_URL}/api/transactions/refunds?count=${pageCount}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** Get a list of all refunded transactions based on passed filter*/
export const getRefundedTransactionsFiltered = (token, filterQuery, pageCount) =>
  fetch(`${API_BASE_URL}/api/transactions/refunds?count=${pageCount}&${filterQuery}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

/** Get details of a refunded transaction by transaction id */
export const getRefundedTransaction = (token, transactionID) => 
  fetch(`${API_BASE_URL}/api/transaction/${transactionID}/refunds`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    redirect: 'error',
  })
    .then(handleSignIn)
    .then((r) => r.json())

