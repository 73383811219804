import './loading-spinner.styles.scss'
import PropTypes from 'prop-types'

const LoadingSpinner = ({ color = '$primary' }) => {
  return <div className='sbl-circ-ripple' style={color ? { color: color } : null}></div>
}

LoadingSpinner.propTypes = {
  color: PropTypes.string,
}

export default LoadingSpinner
