import { useHistory } from 'react-router-dom'

const KycOwnerSelect = ({ owner, owners }) => {
  const history = useHistory()
  const changeOwner = async (owner) => {
    if (owner) {
      history.push(`/know-your-customer/${owner}`)
    }
  }

  const orgOwners = owners.filter((x) => x.type == 'organisation')
  const merchantOwners = owners.filter((x) => x.type == 'merchant')

  return (
    <div className='kyc-owner-select'>
      <select value={owner} onChange={(event) => changeOwner(event.target.value)}>
        {orgOwners.length > 0 ? (
          <optgroup label='Organisations'>
            {orgOwners.map((o) => (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            ))}
          </optgroup>
        ) : null}
        {merchantOwners.length > 0 ? (
          <optgroup label='Merchants'>
            {merchantOwners.map((o) => (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            ))}
          </optgroup>
        ) : null}
      </select>
    </div>
  )
}

export default KycOwnerSelect
