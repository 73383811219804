import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { deleteAllowDenyEntry } from '../api'
import { useAuth } from '../auth'
import { useOrgScope, orgName } from '../org-scope'
import Type from '../settings/allowdeny/Type'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { isOperator, hasContextContractID } from '../auth-roles'

const ViewTestDetail = ({ data, onDone, onCancel }) => {
  const { token, roles } = useAuth()
  const isContractUser = hasContextContractID(roles)
  const isUserOperator = isOperator(roles)

  const { byID, tree } = useOrgScope()
  const entryOrg = (byID && byID[data?.why?.scope]) || null
  const canRemove = entryOrg != null

  // Remove entry
  const [delEntry, setDelEntry] = useState({
    saving: false,
    failed: null,
    req: data?.why,
    res: null,
  })
  const deleteEntry = async () => {
    if (
      !window.confirm(
        `Are you sure you want to remove ${delEntry?.req?.match} ? This will affect all subsequent matching transactions.`
      )
    ) {
      return
    }
    setDelEntry({ ...delEntry, saving: true, failed: null, res: null })
    try {
      const res = await deleteAllowDenyEntry(token, delEntry.req)
      setDelEntry({ saving: false, failed: null, res })
      if (onDone != null) {
        onDone()
      }
    } catch (failed) {
      setDelEntry({ saving: false, failed, res: null })
      window.alert(
        `Something went wrong while trying to remove ${delEntry?.req?.match} - please try again.`
      )
    }
  }

  return (
    <div className='view-allow-deny-entry'>
      <header>
        <h1>Matching Allow/Deny Entry</h1>
        {onCancel != null && (
          <button onClick={onCancel} title='Cancel' className='close clickable'>
            <i className='fas fa-times' />
          </button>
        )}
      </header>
      <div className='content'>
        <p>
          An entry in the{' '}
          <strong>
            {data?.result === 'a' ? 'allow' : data?.result === 'd' ? 'deny' : 'allow/deny'}
          </strong>{' '}
          list matches this value:
        </p>
        <div className='field-with-label'>
          <label>
            <Type type={data?.why?.type} />:
          </label>
          <div className={classnames({ match: true, [data?.result]: data?.result })}>
            {data?.why?.match || '(value hidden)'}
          </div>
        </div>
        {entryOrg && (
          <div className='field-with-label'>
            <label>Scope:</label>
            <div>{entryOrg ? orgName(entryOrg, tree) : '(invisible)'}</div>
          </div>
        )}
        {entryOrg?.id && (isUserOperator || isContractUser) && (
          <div className='field-with-label'>
            <label>Detail:</label>
            <div>
              <Link to={`/settings/allowdeny/${entryOrg.id}`}>
                See allow/deny list for this scope
              </Link>
            </div>
          </div>
        )}
        <footer className='actions'>
          <button className='cancel' onClick={onCancel}>
            {canRemove ? 'Cancel' : 'OK'}
          </button>
          {canRemove && (
            <button className='remove' onClick={deleteEntry}>
              Remove this entry
            </button>
          )}
        </footer>
      </div>
    </div>
  )
}

ViewTestDetail.propTypes = {
  /** If provided, this function is called on successful change, e.g. removal of of the entry */
  onDone: PropTypes.func,
  /** If provided, this function is called upon user cancelletaion of the process before adding an entry*/
  onCancel: PropTypes.func,
  data: PropTypes.object.isRequired,
}

export default ViewTestDetail
