import * as XLSX from 'xlsx'
import { useEffect, useState } from 'react'

const PSPExcelSummaryTable = ({ data }) => {
  let excelData = []
  const [sheetData, setSheetdata] = useState([])

  const formatData = () => {
    excelData = []
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        for (const [key1, value1] of Object.entries(value)) {
          let object = {
            name: key1,
            pending: value1.pending,
            failed: value1.failed,
            ok: value1.ok,
            total: value1.total,
            acceptance_rate: (value1.acceptance_rate * 100).toFixed(1).toString() + '%',
            settled_amount_eur:
              '€' +
              Math.trunc(value1.sum_Settled_Amount_EUR)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            requested_amount_eur:
              '€' +
              Math.trunc(value1.sum_Requested_Amount_EUR)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          }
          excelData.push(object)
        }
      }
      setSheetdata(excelData)
    }
  }

  useEffect(() => {
    setSheetdata([])
    formatData()
  }, [data])

  const handleExport = () => {
    var wb = XLSX.utils.book_new()
    var ws = XLSX.utils.json_to_sheet(sheetData)
    XLSX.utils.book_append_sheet(wb, ws, 'PSP Summary')
    XLSX.writeFile(wb, 'PSPTableSummary.xlsx')
  }
  return (
    <div className='excel-download-container'>
      <button onClick={() => handleExport()}>
        <i className='fas fa-file-excel' style={{ margin: 'auto' }}></i>
      </button>
    </div>
  )
}

export default PSPExcelSummaryTable
