import './routingRules.scss'
import Header from '.././Header'
import Helmet from 'react-helmet'
import { useEffect, useState } from 'react'
import { getFlexibleRules, getFlexibleRulesById, setRulesLive } from '../api'
import { useAuth } from '../auth'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import RoutingTabs from './tabs/RoutingTabs'

const RoutingRules = () => {
  const { token } = useAuth()

  const [routingRules, setRoutingRules] = useState([])
  const [routingRulesList, setRoutingRulesList] = useState()
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState()
  const [errorHandler, setErrorHandler] = useState()
  const [selectState, setSelectState] = useState('Routing-2')

  const setLive = async (id) => {
    try {
      await setRulesLive(token, selectState, id)
      getAllRoutingRules()
    } catch (failed) {
      console.log('could not set rules live: ', failed)
    }
  }

  const getRulesById = async (rule) => {
    try {
      const result = await getFlexibleRulesById(
        token,
        selectState,
        rule.id
      )

      setDescription(rule?.description)
      if (result.type == 'grule') {
        setRoutingRules(result.text)
      } else {
        setRoutingRules(JSON.stringify(result.text, null, 4))
      }
      setErrorHandler()
    } catch (e) {
      setErrorHandler(e?.details)
    }
  }

  const getAllRoutingRules = async () => {
    try {
      const result = await getFlexibleRules(token, selectState)
      const rulesets = result?.rulesets
      setRoutingRulesList(rulesets)
      setErrorHandler()
    } catch (e) {
      setErrorHandler(e?.details)
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    getAllRoutingRules()
  }, [selectState])

  return (
    <section>
      <Header />
      <Helmet>
        <title>Routing Rules</title>
      </Helmet>
      <div className='routing-content'>
        <div className='routing-rules-upload-section'>
          <h1 className='routing-rules-header'>Rules</h1>
          <select
            disabled={loading}
            defaultValue={selectState}
            onChange={(e) => {
              setSelectState(e.target.value)
            }}
            className='routing-rules-dropdown'
            name='rule-type'
          >
            <optgroup label='Classification'>
              <option value='Classification'>Classification</option>
            </optgroup>
            <optgroup label='Routing'>
              <option value='Routing-1'>Routing (1)</option>
              <option value='Routing-2'>Routing (2)</option>
            </optgroup>
            <optgroup label='Contracts'>
              <option value='Contract-1'>Contracts (1)</option>
              <option value='Contract-2'>Contracts (2)</option>
            </optgroup>
            <optgroup label='KYC'>
              <option value='KYC-1'>KYC (1)</option>
              <option value='KYC-2'>KYC (2)</option>
            </optgroup>
          </select>
          <div className='routing-text-wrapper'>
            <div className='routing-pos-relative routing-col'>
              <div className='table-container'>
                <table>
                  <thead>
                    <tr>
                      <td className='routing-table-header'>Status</td>
                      <td className='routing-table-header'>Creation Date</td>
                      <td className='routing-table-header'>Deployment Date</td>
                      <td className='routing-table-header'>Description</td>
                      <td className='routing-table-header'>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {routingRulesList?.map((rule) => {
                      return (
                        <tr
                          key={rule.id}
                          className={rule?.description == description ? 'active-rule-view' : null}
                        >
                          <td className='routing-table-data'>
                            <div
                              className={`${
                                rule?.live ? 'status-green' : 'status-grey'
                              } status-indicator`}
                            ></div>
                          </td>
                          <td className='routing-table-data'>{rule.created_at}</td>
                          <td className='routing-table-data'>{rule.deployed_at}</td>
                          <td className='routing-table-data'>{rule.description}</td>
                          <td className='routing-table-data'>
                            <div className='routing-table-action-items'>
                              <button
                                onClick={() => getRulesById(rule)}
                                className='routing-table-action-button'
                                title='Display ruleset'
                              >
                                <i className='fas fa-eye'></i>
                              </button>
                              <button
                                onClick={() => setLive(rule.id)}
                                className='routing-table-action-button'
                                disabled={rule?.live}
                                title='Deploy ruleset'
                              >
                                <i className='fas fa-globe'></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              {loading && (
                <div className='routing-spinner'>
                  <LoadingSpinner />
                </div>
              )}
            </div>
            <div className='routing-pos-relative routing-col'>
              <RoutingTabs
                routingRules={routingRules}
                setRoutingRules={setRoutingRules}
                routingRulesList={routingRulesList}
                setRoutingRulesList={setRoutingRulesList}
                description={description}
                setDescription={setDescription}
                errorHandler={errorHandler}
                setErrorHandler={setErrorHandler}
                state={selectState}
              />
              {loading && (
                <div className='routing-spinner'>
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RoutingRules
