import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders a merchants id
 */

const MerchantContractID = ({merchant_contract_id }) => (
  <div className='id-container'>
    <div className='merchant_contract_id' title='Merchant Contract ID'>
      <span className='id'>{merchant_contract_id || '-'}</span>
    </div>
  </div>
)
MerchantContractID.propTypes = {
    merchant_contract_id: PropTypes.string,
}

export default MerchantContractID
