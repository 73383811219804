import { useRouteMatch, Route, Switch, Redirect } from 'react-router-dom'
import ListKyc from './ListKyc'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import { useEffect, useState } from 'react'
import { getKycOwners } from '../api'
import { useAuth } from '../auth'
import ViewKyc from './ViewKyc'

const KycRouter = () => {
  const [state, setState] = useState({ owners: [] })
  const { path } = useRouteMatch()
  const { token } = useAuth()

  const loadKycOwners = async () => {
    let data = await getKycOwners(token)
    data = data.toSorted((a, b) => a.name.localeCompare(b.name))
    console.log(data)
    if (data.length > 0) {
      setState({ owners: data })
    }
  }

  useEffect(() => {
    loadKycOwners()
  }, [])

  if (state.owners.length == 0) {
    return (
      <div className='kyc-spinner-wrapper'>
        <LoadingSpinner></LoadingSpinner>
      </div>
    )
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect
          to={`${path}/${
            (
              state.owners.find((x) => x.type == 'organisation') ??
              state.owners.find((x) => x.type == 'merchant')
            ).id
          }`}
        />
      </Route>
      <Route exact path={`${path}/:owner`}>
        <ListKyc owners={state.owners} />
      </Route>
      <Route exact path={`${path}/:owner/:email`} component={ViewKyc} />
    </Switch>
  )
}

export default KycRouter
