import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders a merchants id
 */
const MerchantID = ({ merchant_id }) => (
  <div className='id-container'>
    <div className='merchant_id' title='Merchant ID'>
      <span className='id'>{merchant_id || '-'}</span>
    </div>
  </div>
)
MerchantID.propTypes = {
  merchant_id: PropTypes.string,
}

export default MerchantID
