import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import { assoc } from 'ramda'

const TxnIDFilter = ({
  filter,
  setFilter,
  textEntryDebounceMs = 2000,
  isOperatorOrController = false,
}) => {
  // Which 'mode' are we in?
  const [mode, setMode] = useState(
    filter.order_ref_cnts
      ? 'order_ref_cnts'
      : filter.psp_txn_id_cnts
      ? 'psp_txn_id_cnts'
      : 'txn_id_cnts'
  )
  // Track updates in local state for a smooth typing
  // experience.
  const [id, setID] = useState(filter[mode])

  // updateParentFilter calls the given `setFilter`
  // based on local state, i.e. TXN ID to search for.
  const updateParentFilter = () => {
    const unset =
      mode === 'order_ref_cnts'
        ? ['txn_id_cnts', 'psp_txn_id_cnts']
        : mode === 'psp_txn_id_cnts'
        ? ['order_ref_cnts', 'txn_id_cnts']
        : ['order_ref_cnts', 'psp_txn_id_cnts']

    let newFilter = { ...filter, [mode]: id }
    unset.forEach((u) => {
      newFilter = assoc(u, null, newFilter)
    })

    setFilter(newFilter)
  }

  // But only call `setFilter` debounced, so that we don't
  // cause rerenders and API calls with every keystroke.
  const updateParentFilterDebounced = useDebouncedCallback(updateParentFilter, textEntryDebounceMs)

  // Called when the value changes
  const changed = (e) => {
    setID(e.target.value)
    updateParentFilterDebounced.callback()
  }

  // Mode drop-down changed
  const modeChanged = (e) => {
    const m = e.target.value
    setMode(m)
    setID('')
    updateParentFilter()
    // TODO: Focus text field after change
  }

  // TODO: On enter, update immediately
  const keyPressed = (e) => {
    if (e.key === 'Enter') {
      updateParentFilter()
    }
  }

  return (
    <div className='filter txn_id'>
      <select value={mode} onChange={modeChanged}>
        <option value='txn_id_cnts'>ID</option>
        <option value='order_ref_cnts'>Order ref</option>
        {isOperatorOrController && <option value='psp_txn_id_cnts'>PSP Transaction ID</option>}
      </select>
      <input
        type='text'
        value={id || ''}
        onKeyPress={keyPressed}
        onChange={changed}
        placeholder={
          mode === 'txn_id_cnts'
            ? 'ID Contains'
            : mode === 'order_ref_cnts'
            ? 'Order ref contains'
            : 'PSP transaction id contains'
        }
      />
    </div>
  )
}

TxnIDFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  textEntryDebounceMs: PropTypes.number,
  isOperatorOrController: PropTypes.bool,
}

export default TxnIDFilter
