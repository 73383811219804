/** parsePage produces a `PaginationRequest` (which is passed in to the back-end)
 * using the given current URLSearchParams.
 */
export const parsePage = (params /* URLSearchParams */) => ({
  // These go to the back-end
  count: params.get('count'),
  after: params.get('after'),
  // These track state 'locally' (in the UI), since we have very
  // limited pagination support in the back-end at the moment.
  // TODO: Is there a previous page? What is `after` for that page
})

/** updateSearchParamsWithPagination takes an existing URLSearchParams and
 * updates it canonically using the given `page` object. */
export const updateSearchParamsWithPagination = (params, page = {}) => {
  if (!page) {
    return
  }
  if (page.count) {
    params.set('count', page.count)
  } else {
    params.delete('count')
  }
  if (page.after) {
    params.set('after', page.after)
  } else {
    params.delete('count')
  }
  return params.toString()
}
