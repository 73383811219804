import PropTypes from 'prop-types'
import MerchantName from './MerchantName'
import ContractDescription from './ContractDescription'

/**
 * MerchantStatus renders the merchant status (active or not active)
 */
const MerchantParentOrg = (merchant = {}) => {
  return (
    <span className='org-name'>
      {merchant.part_of_id ? (
        <MerchantName id={merchant.part_of_id} />
      ) : merchant.contract_id ? (
        <ContractDescription id={merchant.contract_id} />
      ) : (
        <span>-</span>
      )}
    </span>
  )
}

MerchantParentOrg.propTypes = {
  // The merchant object
  merchant: PropTypes.object,
}

export default MerchantParentOrg
