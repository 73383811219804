import PropTypes from 'prop-types'
import { useState } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { useAuth } from './auth'
import { useTheme } from './theme'
import { GetToolbarIcon } from './toolbarHelper'
import {
  isOperator,
  hasContextContractID,
  isControllerNoMerchant,
  isController,
  isKYC,
} from './auth-roles'

/** Top-level header with navigation elements. */
const Header = ({ children }) => {
  const theme = useTheme()
  const history = useHistory()
  const { token, roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const isContractUser = hasContextContractID(roles)
  const isControllerNoMer = isControllerNoMerchant(roles)
  const isUserController = isController(roles)
  const isUserKYC = isKYC(roles)

  const [toggleSideMenu, setToggleSideMenu] = useState(false)

  const isMobile = window.matchMedia('(max-width: 420px)').matches

  const desktopHeaderMenu = () => {
    return (
      <nav>
        {(isUserOperator || isControllerNoMer || isUserController) && (
          <NavLink to='/dashboard?from=m24h&to=now'>
            {GetToolbarIcon(theme.id, 'dashboard')}
            <span className='text'>Dashboard</span>
          </NavLink>
        )}

        <NavLink to='/transactions?from=m24h&to=now'>
          {GetToolbarIcon(theme.id, 'transactions')}
          <span className='text'>Transactions</span>
        </NavLink>
        <NavLink to='/refunds'>
          {GetToolbarIcon(theme.id, 'refunds')}
          <span className='text'>Refunds</span>
        </NavLink>

        {(isUserOperator || isUserKYC) && (
          <NavLink to='/know-your-customer'>
            {GetToolbarIcon(theme.id, 'kyc')}
            <span className='text'>KYC</span>
          </NavLink>
        )}

        {isUserOperator && (
          <NavLink to='/merchants'>
            {GetToolbarIcon(theme.id, 'merchants')}
            <span className='text'>Merchants</span>
          </NavLink>
        )}
        {isUserOperator && (
          <NavLink to='/users'>
            {GetToolbarIcon(theme.id, 'users')}
            <span className='text'>Users</span>
          </NavLink>
        )}
        {isUserOperator && (
          <NavLink to='/routing-rules'>
            {GetToolbarIcon(theme.id, 'rules')}
            <span className='text'>Rules</span>
          </NavLink>
        )}
        {(isUserOperator || isContractUser) && !isControllerNoMer && (
          <NavLink to='/settings'>
            {GetToolbarIcon(theme.id, 'settings')}
            <span className='text'>Settings</span>
          </NavLink>
        )}
        <NavLink to='/support'>
          {GetToolbarIcon(theme.id, 'support')}

          <span className='text'>Support</span>
        </NavLink>
        {token && (
          <NavLink to='/account'>
            {GetToolbarIcon(theme.id, 'account')}

            <span className='text'>Account</span>
          </NavLink>
        )}
      </nav>
    )
  }

  const mobileHeaderMenu = () => {
    return (
      <div onClick={() => setToggleSideMenu(!toggleSideMenu)}>
        {GetToolbarIcon(theme.id, 'mobile-menu')}
      </div>
    )
  }
  const SideMenu = ({ toggleSideMenu }) => {
    return (
      <div
        className={`${
          toggleSideMenu ? 'side-menu-open' : 'side-menu-closed'
        } side-menu theme-header-background`}
      >
        <div className='sidemenu-header'>
          <span onClick={() => setToggleSideMenu(!toggleSideMenu)}>X</span>
        </div>
        <div className='sidemenu-item-container'>
          <NavLink to='/dashboard?from=m24h&to=now'>
            {GetToolbarIcon(theme.id, 'dashboard')}
            <span className='text'>Dashboard</span>
          </NavLink>
        </div>
        <div className='sidemenu-item-container'>
          <NavLink to='/transactions?from=m24h&to=now'>
            {GetToolbarIcon(theme.id, 'transactions')}
            <span className='text'>Transactions</span>
          </NavLink>
        </div>
        <div className='sidemenu-item-container'>
          {(isUserOperator || isControllerNoMer) && (
            <NavLink to='/know-your-customer'>
              {GetToolbarIcon(theme.id, 'kyc')}
              <span className='text'>KYC</span>
            </NavLink>
          )}
        </div>
        <div className='sidemenu-item-container'>
          {isUserOperator && (
            <NavLink to='/merchants'>
              {GetToolbarIcon(theme.id, 'merchants')}
              <span className='text'>Merchants</span>
            </NavLink>
          )}
        </div>
        <div className='sidemenu-item-container'>
          {isUserOperator && (
            <NavLink to='/users'>
              {GetToolbarIcon(theme.id, 'users')}
              <span className='text'>Users</span>
            </NavLink>
          )}
        </div>
        <div className='sidemenu-item-container'>
          {isUserOperator && (
            <NavLink to='/routing-rules'>
              {GetToolbarIcon(theme.id, 'rules')}
              <span className='text'>Rules</span>
            </NavLink>
          )}
        </div>
        <div className='sidemenu-item-container'>
          {(isUserOperator || isContractUser) && !isControllerNoMer && (
            <NavLink to='/settings'>
              {GetToolbarIcon(theme.id, 'settings')}
              <span className='text'>Settings</span>
            </NavLink>
          )}
        </div>
        <div className='sidemenu-item-container'>
          <NavLink to='/support'>
            {GetToolbarIcon(theme.id, 'support')}

            <span className='text'>Support</span>
          </NavLink>
        </div>
        <div className='sidemenu-item-container'>
          {token && (
            <NavLink to='/account'>
              {GetToolbarIcon(theme.id, 'account')}

              <span className='text'>Account</span>
            </NavLink>
          )}
        </div>
      </div>
    )
  }

  const goHome = () => history.push('/')
  return (
    <>
      <header className='theme-header-background'>
        {isMobile && mobileHeaderMenu(setToggleSideMenu)}
        {!children && <span className='logo theme-logo' onClick={goHome}></span>}
        {children}
        {!isMobile && desktopHeaderMenu()}
      </header>
      <div className='theme-header-border'></div>
      <SideMenu toggleSideMenu={toggleSideMenu} />
    </>
  )
}
Header.propTypes = {
  children: PropTypes.any,
}

export default Header
