import React from 'react'
import PropTypes from 'prop-types'
import { useOrgScope, orgName, OrgScopeRequired } from './org-scope'

/** OrgScopeSelector presents the user with a control that allows the user to pick a single organisation
 * or "level of abstraction" from within the tree that the user is allowed to see. It is thus not useful
 * as a general selector, e.g. "pick merchant", but rather, userful whenever a user has to pick a level
 * in the hierarchy, "up to" some specific leaf scope, to apply something to - such as an allow/deny entry.
 */
const OrgScopeSelector = ({ leafScope = '', value = '', onChange }) => {
  const { tree, branch } = useOrgScope(leafScope)
  const userRoot = tree // TODO: 'tree' will become 'trees' (list)
  const scopes = branch ? branch.filter((o) => o != null).reverse() : []
  const onValueChange = (e) => {
    if (onChange != null) {
      onChange(e.target.value)
    }
  }
  return (
    <div className='org-scope-selector'>
      <OrgScopeRequired>
        {scopes.map((org, n) => (
          <label className='option' key={org.id}>
            <input
              type='radio'
              checked={org.id === value}
              value={org.id}
              onChange={onValueChange}
            />{' '}
            {orgName(org, userRoot)} {n === 0 && <span className='only'>only</span>}
            {/* TODO: Child count, e.g. (3 brands) */}
          </label>
        ))}
      </OrgScopeRequired>
    </div>
  )
}

OrgScopeSelector.propTypes = {
  /** ID of the most specific (leaf-most) org in the users's org tree that can be picked.
   * The user will be able to pick anything from this level and "up", if any parent scopes
   * are indeed visible to the user.  */
  leafScope: PropTypes.string.isRequired,
  /* Currently-selected value (this component acts like a bound form component). */
  value: PropTypes.string.isRequired,
  /* Function: OrgID -> () - used to signal when the user changes the value. */
  onChange: PropTypes.func.isRequired,
}

export default OrgScopeSelector
