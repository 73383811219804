import React from 'react'
import PropTypes from 'prop-types'

/** Renders a transaction ID.
 * TODO: Add icon to "copy to clipboard" when clicked
 */
const TxnID = ({ transaction_id, order_ref }) => (
  <div className='id-container'>
    <div className='txn_id' title='Internal Transaction ID'>
      <span className='id'>{transaction_id || '-'}</span>
    </div>
    {order_ref && (
      <div className='order_ref' title='Merchant-provided order reference'>
        Order ref: <span className='id'>{order_ref}</span>
      </div>
    )}
  </div>
)

TxnID.propTypes = {
  transaction_id: PropTypes.string,
  order_ref: PropTypes.string,
}

export default TxnID
