import React from 'react'
import PropTypes from 'prop-types'

const AmountWithCurrency = ({ amount = 0, currency = null }) => {
  var formatted
  try {
    formatted = amount.toLocaleString(undefined, {
      style: 'currency',
      currency,
    })
  } catch (e) {
    // Bad currency? Try to format as number
    formatted = amount.toLocaleString()
  }
  return (
    <span className='money-amount'>
      {/*<span className='ccy'>{currency}</span>{' '}*/}
      <strong className='amount'>{formatted}</strong>
    </span>
  )
}
AmountWithCurrency.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string,
}
export default AmountWithCurrency
