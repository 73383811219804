import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useOrgScope } from '../org-scope'
import { pathOr } from 'ramda'

/**
 * ContractDescription renders the contract description
 * We specifically accept a list of contracts for this component as we generally have this in state
 *  TODO replace with tree from context when its available
 */
const ContractDescription = ({ id }) => {
  const { byID } = useOrgScope()
  // if we can find the contract id, try and return contract name otherwise fallback to showing only id
  const contractDescription = (id) => {
    if (!id) {
      return '-'
    }

    return pathOr(id, [id, 'name'], byID)
  }

  return (
    <span className={classnames({ contract: true })}>
      <span className='name'>{contractDescription(id)}</span>
    </span>
  )
}

ContractDescription.propTypes = {
  id: PropTypes.string,
  contracts: PropTypes.array,
}

export default ContractDescription
