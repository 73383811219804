import React from 'react'

const Interval = ({ liveUpdateInterval, handleChange, disabled }) => {
  return (
    <select value={liveUpdateInterval} onChange={handleChange} disabled={disabled}>
      <option value={"don't update"}>don't update</option>
      <option value={300000}>5min</option>
      <option value={600000}>10min</option>
      <option value={900000}>15min</option>
      <option value={1200000}>20min</option>
    </select>
  )
}

export default Interval
