import { Link } from 'react-router-dom'
import { useTheme } from './theme'

const Footer = () => {
  const theme = useTheme()

  return (
    <footer>
      <span className='copyright'>&copy; 2022 {theme.name}</span>
      <span className='links'>
        <Link to='/support'>Support</Link>
      </span>
      {/* TODO: Terms of use, etc */}
    </footer>
  )
}

export default Footer
