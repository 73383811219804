import React, { useEffect, useState } from 'react'
import PSPExcelSummaryTable from '../excel-templates//PSPExcelSummaryTable'
import PropTypes from 'prop-types'

const PSPTransactionSpendTable = ({ data, failed, loading, title }) => {
  const [tableData, setTableData] = useState([])
  const [tableTotals, setTableTotals] = useState()
  const isMobile = window.matchMedia('(max-width: 420px)').matches

  const transformData = () => {
    if (data) {
      let tempArray = []
      const totals = {
        pending: 0,
        failed: 0,
        ok: 0,
        abandoned: 0,
        total: 0,
        requested: 0,
        settled: 0,
        ar: 0,
      }
      for (const [key, value] of Object.entries(data)) {
        const parentObject = {
          name: key,
          pending: 0,
          failed: 0,
          ok: 0,
          abandoned: 0,
          total: 0,
          requested: 0,
          settled: 0,
          ar: 0,
          children: [],
        }
        for (const [key, childObjectValue] of Object.entries(value)) {
          let childObject = {
            name: key,
            midid: childObjectValue.midid,
            pending: childObjectValue.pending,
            failed: childObjectValue.failed,
            ok: childObjectValue.ok,
            abandoned: childObjectValue.abandoned,
            total: childObjectValue.ok + childObjectValue.pending + childObjectValue.failed,
            requested: childObjectValue.sum_Requested_Amount_EUR,
            settled: childObjectValue.sum_Settled_Amount_EUR,
            ar: childObjectValue.acceptance_rate,
          }
          parentObject.children.push(childObject)
          parentObject.pending += childObjectValue.pending
          parentObject.failed += childObjectValue.failed
          parentObject.ok += childObjectValue.ok
          parentObject.abandoned += childObjectValue.abandoned
          parentObject.total +=
            childObjectValue.pending + childObjectValue.failed + childObjectValue.ok
          parentObject.requested += childObjectValue.sum_Requested_Amount_EUR
          parentObject.settled += childObjectValue.sum_Settled_Amount_EUR
          parentObject.ar =
            parentObject.ok + parentObject.failed
              ? parentObject.ok / (parentObject.ok + parentObject.failed)
              : 0
        }
        totals.pending += parentObject.pending
        totals.failed += parentObject.failed
        totals.ok += parentObject.ok
        totals.abandoned += parentObject.abandoned
        totals.total += parentObject.total
        totals.requested += parentObject.requested
        totals.settled += parentObject.settled
        totals.ar = totals.ok + totals.failed ? totals.ok / (totals.ok + totals.failed) : 0
        tempArray.push(parentObject)
      }
      setTableTotals(totals)
      setTableData(tempArray)
    }
  }

  const formatCurrency = (value) => {
    if (isMobile) {
      return Math.abs(value) > 999
        ? (Math.abs(value) / 1000).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'k'
        : Math.abs(value)
    } else {
      return Math.trunc(value)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }

  useEffect(() => {
    transformData()
  }, [data])

  return data && Object.keys(data).length && !loading && !failed ? (
    <>
      <div className={'sub-header-table-view-wrapper'}>
        <div className='graph-title-container'>
          {!isMobile && <PSPExcelSummaryTable data={data} />}
          <span className='table-title'>{title}</span>
        </div>
        <table className='psp-transaction-spend-table' cellPadding='0' cellSpacing='0'>
          <thead className='header-row'>
            <tr>
              <th>MID</th>
              {!isMobile && <th>Pending</th>}
              {!isMobile && <th>Failed</th>}
              {!isMobile && <th>Ok</th>}
              <th>Total</th>
              {!isMobile && <th>Requested (EUR)</th>}
              <th>Settled (EUR)</th>
              <th>AR</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((s, index) => {
              return (
                <React.Fragment key={index}>
                  <tr className='sub-header-row' key={index + s.settled}>
                    <td className={`table-data-left-alignment`}>{s.name}</td>
                    {!isMobile && (
                      <td className='table-data-number-format'>{formatCurrency(s.pending)}</td>
                    )}
                    {!isMobile && (
                      <td className='table-data-number-format'>
                        {formatCurrency(s.failed + s.abandoned)}
                      </td>
                    )}
                    {!isMobile && (
                      <td className='table-data-number-format'>{formatCurrency(s.ok)}</td>
                    )}
                    <td className={`table-data-number-format`}>{formatCurrency(s.total)}</td>
                    {!isMobile && (
                      <td className='table-data-number-format'>€ {formatCurrency(s.requested)}</td>
                    )}
                    <td className={'table-data-number-format'}>€ {formatCurrency(s.settled)}</td>
                    <td className={`table-data-number-format`}>{(s.ar * 100).toFixed(1)}%</td>
                  </tr>
                  {s.children.map((childItem, index) => {
                    let ar = (childItem.ar * 100).toFixed(1)
                    return (
                      <tr key={index}>
                        <td className='table-data-left-alignment'>
                          {isMobile ? childItem.midid : childItem.name}
                        </td>
                        {!isMobile && (
                          <td className='table-data-number-format'>
                            {formatCurrency(childItem.pending)}
                          </td>
                        )}
                        {!isMobile && (
                          <td className='table-data-number-format'>
                            {formatCurrency(childItem.failed)}
                          </td>
                        )}
                        {!isMobile && (
                          <td className='table-data-number-format'>
                            {formatCurrency(childItem.ok)}
                          </td>
                        )}
                        <td className='table-data-number-format'>
                          {formatCurrency(childItem.total)}
                        </td>
                        {!isMobile && (
                          <td className='table-data-number-format'>
                            {formatCurrency(childItem.requested)}
                          </td>
                        )}
                        <td className='table-data-number-format'>
                          € {formatCurrency(childItem.settled)}
                        </td>
                        <td
                          className={`table-data-number-format ${
                            ar < 70 && ar > 49
                              ? 'warning-percentage-threshold'
                              : ar < 49
                              ? 'failed-percentage-threshold'
                              : null
                          }`}
                        >
                          {(childItem.ar * 100).toFixed(isMobile ? 0 : 1)}%
                        </td>
                      </tr>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </tbody>
          <tfoot>
            <tr className='header-row'>
              <th>TOTAL</th>
              {!isMobile && (
                <th className='table-data-number-format'>{formatCurrency(tableTotals?.pending)}</th>
              )}
              {!isMobile && (
                <th className='table-data-number-format'>
                  {formatCurrency(tableTotals?.failed ?? 0 + tableTotals?.abandoned ?? 0)}
                </th>
              )}
              {!isMobile && (
                <th className='table-data-number-format'>{formatCurrency(tableTotals?.ok)}</th>
              )}
              <th className='table-data-number-format'>{formatCurrency(tableTotals?.total)}</th>
              {!isMobile && (
                <th className='table-data-number-format'>
                  € {formatCurrency(tableTotals?.requested)}
                </th>
              )}
              <th className='table-data-number-format'>€ {formatCurrency(tableTotals?.settled)}</th>
              <th className='table-data-number-format'>
                {(tableTotals.ar * 100).toFixed(isMobile ? 0 : 1)}%
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  ) : !data?.length && !loading && !failed ? (
    <div className='stacked-bar-chart-wrapper'>
      <span className='table-title'>{title}</span>
      <div className='no-data-dashboard'>
        <h1>NO DATA</h1>
      </div>
    </div>
  ) : null
}

PSPTransactionSpendTable.propTypes = {
  data: PropTypes.object,
  failed: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
}

export default PSPTransactionSpendTable
