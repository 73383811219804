import { useEffect } from 'react'
import { addFlexibleRules, getFlexibleRules } from '../../api'
import { useAuth } from '../../auth'

const CurrentRules = ({
  routingRules,
  setRoutingRules,
  setRoutingRulesList,
  description,
  setDescription,
  errorHandler,
  setErrorHandler,
  state,
}) => {
  const { token } = useAuth()

  const uploadFile = (event) => {
    const fileReader = new FileReader()
    fileReader.readAsText(event.target.files[0], 'UTF-8')
    fileReader.onload = (e) => {
      setRoutingRules(JSON.parse(JSON.stringify(e.target.result)))
    }
  }

  const getAllRoutingRules = async () => {
    try {
      const result = await getFlexibleRules(token, state)
      setRoutingRulesList(result.rulesets)
      setErrorHandler()
    } catch (e) {
      setErrorHandler(e?.details)
    }
  }

  const saveRules = async () => {
    try {
      await addFlexibleRules(token, state, description, routingRules)
      getAllRoutingRules()
      setErrorHandler()
    } catch (e) {
      setErrorHandler(e?.details)
    }
  }

  const setNewRoutingRule = (e) => {
    setRoutingRules(e.target.value)
  }

  useEffect(() => {
    setRoutingRules('')
    setDescription('')
  }, [state])

  return (
    <>
      <div className='ruleset-action-container'>
        <input
          type='text'
          placeholder='Ruleset Description'
          size='60'
          value={description}
          onChange={(event) => setDescription(event?.target?.value)}
        />
        <button
          className='action-button'
          onClick={saveRules}
          disabled={!description || !routingRules}
        >
          Save
        </button>
        <input type='file' onChange={uploadFile} id='file' style={{ display: 'none' }} />
        <label htmlFor='file'>
          <p className='upload-button'>Upload File</p>
        </label>
        <p className={`${errorHandler ? null : 'error-message-hidden'} error-message`}>
          {errorHandler}
        </p>
      </div>
      <textarea
        onChange={setNewRoutingRule}
        className='routing-text-area'
        value={routingRules}
        placeholder={`${state.split('-')[0]} Rules`}
      ></textarea>
    </>
  )
}
export default CurrentRules
