import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import { assocPath } from 'ramda'

const KycVerifiedEmailFilter = ({ filter, filterName, setFilter }) => {
  const [filterValue, setFilterValue] = useState(filter[filterName] ? filter[filterName] : '')

  const updateParentFilter = () => {
    setFilter(assocPath([filterName], filterValue, filter))
  }

  const updateParentFilterDebounced = useDebouncedCallback(updateParentFilter, 0)

  const changed = (e) => {
    setFilterValue(e.target.value)
    updateParentFilterDebounced.callback()
  }

  return (
    <div className='filter card'>
      <div className='filter'>
        <select value={filterValue.toString() || ''} onChange={changed} className='full-width'>
          <option value=''>Any</option>
          <option value='true'>Verified</option>
          <option value='false'>Not verified</option>
        </select>
      </div>
    </div>
  )
}

export default KycVerifiedEmailFilter
