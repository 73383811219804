import { useState, useEffect } from 'react'
import { getDashboardStats } from '../api'

import Header from '../Header'
import { Helmet } from 'react-helmet'
import StackedBarChart from './charts/StackedBarChart'
import CountryStatsBarChart from './charts/CountryStatsBarChart'
import Interval from './Intervals'
import { useHistory } from 'react-router-dom'

import { useInterval } from '../timing/use-interval'
import { useAuth } from '../auth'
import { parseTransactionFilters, updateSearchParamsWithFilters } from '../txns/filters'
import PSPFilter from '../txns/filters/PSPFilter'
import CountryFilter from '../txns/filters/CountryFilter'
import PSPMIDFilter from '../txns/filters/PSPMIDFilter'
import MerchantFilter from '../txns/filters/MerchantFilter'
import GenericTextFilter from '../filters/GenericTextFilter'

import { useOrgScope } from '../org-scope'
import { isControllerNoMerchant, isOperator } from '../auth-roles'
import TimeFilter from '../filters/TimeFilter'
import ToggleButton from '../components/ToggleButton'
import PSPTransactionSpendTable from './tables/PSPTransactionSpendTable'

const Dashboard = () => {
  const [dashboardStats, setDashboardStats] = useState()
  const [dashboardStatsLoading, setDashboardStatsLoading] = useState(true)
  const [dashboardStatsFailed, setDashboardStatsFailed] = useState(false)
  const history = useHistory()
  const { token, roles } = useAuth()
  const urlParams = new URLSearchParams(location.search)
  const filter = parseTransactionFilters(urlParams)
  const [liveUpdateInterval, setLiveUpdateInterval] = useState("don't update")
  const { byID } = useOrgScope()
  const isUserOperator = isOperator(roles)
  const shouldHideTopMerchants = isControllerNoMerchant(roles)
  const [dashboardTable, setDasboardTable] = useState(false)

  const isMobile = window.matchMedia('(max-width: 420px)').matches

  const fetchDashboardStats = async () => {
    setDashboardStatsLoading(true)
    try {
      let dashboardStats = await getDashboardStats(token, { filter })
      delete dashboardStats?.psps?.test
      delete dashboardStats?.psps?.empty
      setDashboardStats(dashboardStats)
      setDashboardStatsLoading(false)
    } catch (error) {
      setDashboardStatsFailed(true)
      setDashboardStatsLoading(false)
    }
  }

  const setFilter = (filter = {}) => {
    const updUrlParams = updateSearchParamsWithFilters(urlParams, filter)
    history.replace({ pathname: location.pathname, search: updUrlParams })
  }

  // We only allow update live if date filter is less than 7 days
  // const filtersIsFtdsOnly = () => {
  //   if (filter.ftds_only == 'true') return true
  //   return false
  // }

  // Every time 'FtdsOnly' is toggled to true, fetch txns
  const handleToggleFtdsOnly = (e) => {
    setFilter({ ...filter, ftds_only: e.target.checked })
  }

  const toggleDashboardTable = () => {
    setDasboardTable(!dashboardTable)
  }

  const checkMobile = () => {
    if (isMobile) {
      setDasboardTable(false)
    } else {
      setDasboardTable(true)
    }
  }

  useEffect(() => {
    fetchDashboardStats()
    checkMobile()
  }, [urlParams.toString()])

  const updateLiveInterval = (val) => {
    setLiveUpdateInterval(val.target.value)
  }

  useInterval(() => {
    if (liveUpdateInterval !== "don't update") {
      fetchDashboardStats()
    }
  }, liveUpdateInterval)

  return (
    <section>
      <Header />
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className='content'>
        <div className={dashboardTable ? 'dashboard-table-wrapper' : null}>
          {isMobile && (
            <div className='dashboard-controls'>
              <i className='fa fa-filter' onClick={() => toggleDashboardTable()}></i>
            </div>
          )}
          <table className={dashboardTable ? 'txns dashboard-table' : 'dashboard-table-none'}>
            <thead>
              <tr>
                <th className='dashboard-filter-table-item-left'>
                  <h6>Time</h6>
                  <TimeFilter filter={filter} setFilter={setFilter} />
                </th>
                {isUserOperator && (
                  <th className='dashboard-filter-table-item-left'>
                    <h6>Merchant</h6>
                    {byID && (
                      <MerchantFilter filter={filter} setFilter={setFilter} orgsByID={byID} />
                    )}
                  </th>
                )}
                <th className='dashboard-filter-table-item-left'>
                  <h6>PSP</h6>
                  <PSPFilter filter={filter} setFilter={setFilter} />
                </th>
                <th className='dashboard-filter-table-item-left'>
                  <h6>MID</h6>
                  <PSPMIDFilter filter={filter} setFilter={setFilter} />
                </th>
                <th className='dashboard-filter-table-item-left'>
                  <h6>Country</h6>
                  <CountryFilter filter={filter} setFilter={setFilter} />
                </th>
                <th className='dashboard-filter-table-item-left'>
                  <h6>BIN</h6>
                  <GenericTextFilter
                    filter={filter}
                    filterName='card_cnts'
                    placeholder='BIN'
                    setFilter={setFilter}
                  />
                </th>
                <th className='dashboard-filter-table-item-left'>
                  <h6>Routing Rule</h6>
                  <GenericTextFilter
                    filter={filter}
                    filterName='rule'
                    placeholder='Rule Name'
                    setFilter={setFilter}
                  />
                </th>
                <th className='dashboard-filter-table-item-right'>
                  <h6>Update Frequency</h6>
                  <div className='filter date'>
                    <Interval
                      liveUpdateInterval={liveUpdateInterval}
                      handleChange={updateLiveInterval}
                      disabled={dashboardStatsLoading || dashboardStatsFailed}
                    />
                  </div>
                </th>
                <th className='dashboard-filter-table-item-right'>
                  <h6>Only FTDs</h6>
                  <span className='ftds'>
                    <label>
                      <ToggleButton
                        toggleButtonStatus={filter.ftds_only}
                        handleChange={handleToggleFtdsOnly}
                      />
                      {/* <input
                type='checkbox'
                checked={filtersIsFtdsOnly() || ftdsOnly}
                onChange={handleToggleFtdsOnly}
              /> */}
                    </label>
                  </span>
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className='dashboard-graphs-wrapper'>
          <div className='dashboard-graph'>
            <PSPTransactionSpendTable
              title={'PSP Transactions and Spend'}
              data={dashboardStats?.psps}
              loading={dashboardStatsLoading}
              failed={dashboardStatsFailed}
            />
          </div>
          <div className='dashboard-graph'>
            <StackedBarChart
              keyLabel={'BIN'}
              binStats={dashboardStats?.topBINs}
              binsFailed={dashboardStatsFailed}
              binsLoading={dashboardStatsLoading}
              filter={filter}
              setFilter={setFilter}
              title={'BIN Transactions and Spend'}
            />
          </div>
          <div className='dashboard-graph'>
            <StackedBarChart
              keyLabel={'BIN'}
              binStats={dashboardStats?.poorBINs}
              binsFailed={dashboardStatsFailed}
              binsLoading={dashboardStatsLoading}
              filter={filter}
              setFilter={setFilter}
              title={'Poorly Performing BINs'}
            />
          </div>
          <div className='dashboard-graph'>
            <CountryStatsBarChart
              countryStats={dashboardStats?.countryStats}
              countryStatsFailed={dashboardStatsFailed}
              countryStatsLoading={dashboardStatsLoading}
              filter={filter}
              setFilter={setFilter}
              title={'Country Stats'}
            />
          </div>
          <div className='dashboard-graph'>
            <StackedBarChart
              keyLabel={'Rule'}
              binStats={dashboardStats?.topRoutingRules}
              binsFailed={dashboardStatsFailed}
              binsLoading={dashboardStatsLoading}
              filter={filter}
              setFilter={setFilter}
              title={'Transactions per routing rule'}
            />
          </div>
          {shouldHideTopMerchants ? null : (
            <div className='dashboard-graph'>
              <StackedBarChart
                keyLabel={'MerchantName'}
                binStats={dashboardStats?.topMerchants}
                binsFailed={dashboardStatsFailed}
                binsLoading={dashboardStatsLoading}
                filter={filter}
                setFilter={setFilter}
                title={'Top 20 merchants'}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Dashboard
