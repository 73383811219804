import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import PropTypes from 'prop-types'

const UserIDFilter = ({ filter, setFilter, textEntryDebounceMs = 2000 }) => {
  // Track updates in local state for a smooth typing experience.
  const [id, setID] = useState(filter['user_id_cnts'])

  // updateParentFilter calls the given `setFilter` based on local state
  const updateParentFilter = () => {
    const newFilter = { ...filter, ['user_id_cnts']: id }
    setFilter(newFilter)
  }

  // But only call `setFilter` debounced, so that we don't cause re-renders and API calls with every keystroke.
  const updateParentFilterDebounced = useDebouncedCallback(updateParentFilter, textEntryDebounceMs)

  // Called when the value changes
  const changed = (e) => {
    setID(e.target.value)
    updateParentFilterDebounced.callback()
  }

  // TODO: On enter, update immediately
  const keyPressed = (e) => {
    if (e.key === 'Enter') {
      updateParentFilter()
    }
  }

  return (
    <div className='filter user_id'>
      <input
        type='text'
        value={id || ''}
        onKeyPress={keyPressed}
        onChange={changed}
        placeholder={'ID Contains'}
      />
    </div>
  )
}

UserIDFilter.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  textEntryDebounceMs: PropTypes.number,
}

export default UserIDFilter
