import React from 'react'
import PropTypes from 'prop-types'
import { useOrgScope, TYPE_MERCHANT, getBranchTo, orgName } from './org-scope'
import { groupBy, prop, toPairs, sortBy } from 'ramda'

/** OrgScopeNavigator is a bound component that allows the user the 'navigate'
 * the tree of orgs in scope for the current user, using a simple and compact
 * single-row navigation, similar to breadcrumbs. It is "bound" in that the
 * currently selected or, as well as an `onChange` handler, as passed in, allow
 * users to decide how the state of the currently-selected org is managed - for
 * example, by changing a URL / path parameter. */
const OrgScopeNavigator = ({ value, onChange }) => {
  const { byID, tree, loading, failed } = useOrgScope()
  const userRoot = tree // TODO: 'tree' will become 'trees' (list)

  // If we haven't yet loaded it once, i.e. no data, abort early
  if (loading && !tree) {
    return (
      <span className='org-scope-nav'>
        <span className='loading'>Loading...</span>
      </span>
    )
  }
  // If we failed, abort early
  if (failed) {
    return (
      <span className='org-scope-nav'>
        <span className='error'>Something went wrong loading navigation, please retry.</span>
      </span>
    )
  }

  // Currently-selected org - note, if not available,
  // we pick the highest one that is (but this is a problem, as it may no longer
  // match what parent component thinks is selected - TODO, should we immediately
  // fire one `onChange` for this in useEffect()
  const selected = tree != null && byID != null ? byID[value] : tree
  if (!selected) {
    return null
  }

  // A walk from the top of the tree to the currently selected org
  const pathToSelected = getBranchTo(byID, [selected])

  // UI components for the top-down branch
  const uiUpToSelected = pathToSelected.map((org) =>
    org === selected ? (
      <SelectedOrg key={org.id} org={org} userRoot={userRoot} />
    ) : (
      <ParentOrg key={org.id} org={org} userRoot={userRoot} onChange={onChange} />
    )
  )

  // Optional: If there are children, final UI element is a child selector
  const uiAll =
    selected.children && selected.children.length > 0
      ? [
          ...uiUpToSelected,
          <ChildSelector
            key={`${selected.id}-children`}
            org={selected}
            userRoot={userRoot}
            childs={selected.children}
            onChange={onChange}
          />,
        ]
      : uiUpToSelected

  return <span className='org-scope-nav'>{React.Children.toArray(uiAll)}</span>
}
OrgScopeNavigator.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const ParentOrg = ({ org, userRoot, onChange }) => (
  <span className='org parent'>
    <span className='name clickable' onClick={() => onChange(org.id)}>
      {orgName(org, userRoot)}
    </span>
  </span>
)
ParentOrg.propTypes = {
  org: PropTypes.object.isRequired,
  userRoot: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

const SelectedOrg = ({ org, userRoot }) => (
  <span className='org selected'>{orgName(org, userRoot)}</span>
)
SelectedOrg.propTypes = {
  org: PropTypes.object.isRequired,
  userRoot: PropTypes.object.isRequired,
}

const ChildSelector = ({ org, userRoot, childs = [], onChange }) => {
  const childrenByType = toPairs(groupBy(prop('type'), childs)) // [[type, orgs]]
  const label = summariseChildren(org, childrenByType)
  return (
    <span className='orgs children'>
      <select value='' onChange={(e) => onChange(e.target.value)}>
        <option value=''>{label}</option>
        {
          /* Only one type of child */ childrenByType.length === 1 &&
            childrenByType.map((o) => {
              const orgs = o[1]
              return sortBy(prop('name'), orgs).map((org) => (
                <option key={org.id} value={org.id}>
                  {orgName(org, userRoot)}
                </option>
              ))
            })
        }
        {
          /* Multiple types of children, grouped */ childrenByType.length > 1 &&
            childrenByType.map(([type, orgs]) => (
              <optgroup
                key={type}
                label={org.type === TYPE_MERCHANT && type === TYPE_MERCHANT ? 'brand' : type}
              >
                {sortBy(prop('name'), orgs).map((org) => (
                  <option key={org.id} value={org.id}>
                    {orgName(org, userRoot)}
                  </option>
                ))}
              </optgroup>
            ))
        }
      </select>
    </span>
  )
}
ChildSelector.propTypes = {
  org: PropTypes.object.isRequired,
  userRoot: PropTypes.object.isRequired,
  childs: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

/** summariseChildren produces a 1-liner summary of a grouped ([[type, children]])
 * array of arrays, suitable for use as a label in a UI control.
 * Example output: "17 brands" or "1 contract, 42 merchants" (depending on nature of parent).
 */
const summariseChildren = (parent = {}, groupedChildren = []) =>
  groupedChildren
    .filter(([type, children]) => type && children != null && children.length > 0)
    .map(
      ([type, children]) =>
        `${children.length} ${childTypeInCtxOfParent(parent?.type, type)}${
          children.length != 1 ? 's' : ''
        }`
    )
    .join(', ')

/** childTypeInCtxOfParent provides a good name for the type of a child org,
 * in the context of parent org. Primary purpose is the idea of "brand", which is
 * not a true type, but a contextual name.
 */
const childTypeInCtxOfParent = (parentType, childType) =>
  parentType == TYPE_MERCHANT && childType == TYPE_MERCHANT ? 'brand' : childType

export default OrgScopeNavigator
