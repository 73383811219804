import { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { assocPath } from 'ramda'
import PropTypes from 'prop-types'

const EmailFilter = ({ filter, setFilter, textEntryDebounceMs = 2000 }) => {
  // Track updates in local state for a smooth typing
  // experience.
  const [email, setEmail] = useState(filter.email)

  // updateParentFilter calls the given `setFilter`
  // based on local state, i.e. email to search for.
  const updateParentFilter = () => {
    if (!email) {
      cleared()
    } else {
      if (filter['from'] == 'm24h' && filter['to'] == 'now') {
        setFilter({ ...filter, email: email, from: null, to: null })
      } else {
        setFilter(assocPath(['email'], email, filter))
      }
    }
  }

  // But only call `setFilter` debounced, so that we don't
  // cause rerenders and API calls with every keystroke.
  const updateParentFilterDebounced = useDebouncedCallback(updateParentFilter, textEntryDebounceMs)

  // Called when the value changes
  const changed = (e) => {
    setEmail(e.target.value)
    updateParentFilterDebounced.callback()
  }

  const cleared = () => {
    setEmail('') // Immediate local state

    if (!filter['from'] && !filter['to']) {
      setFilter({ ...filter, email: null, from: 'm24h', to: 'now' }) // Parent filter
    } else {
      setFilter(assocPath(['email'], '', filter)) // Parent filter
    }
  }

  // TODO: On enter, update immediately
  const keyPressed = (e) => {
    if (e.key === 'Enter') {
      updateParentFilter()
    }
  }

  return (
    <div className='filter email'>
      <input
        id='email-filter-input'
        type='text'
        value={email || ''}
        onKeyPress={keyPressed}
        onChange={changed}
        placeholder='user@host.com'
      />
      {email && (
        <button className='clear' onClick={cleared} title='Clear filter'>
          <i className='fas fa-times'></i>
        </button>
      )}
    </div>
  )
}

EmailFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  textEntryDebounceMs: PropTypes.number,
}

export default EmailFilter
