import { acceptancePercent } from '.././txns/stats'

import AmountWithCurrency from '.././AmountWithCurrency'
const AcceptanceRate = ({ cnt_by_status = {}, net_vol_by_settle_ccy = {} }) => {
  const rate = acceptancePercent(cnt_by_status)
  if (rate == null) {
    return null
  }

  return (
    <div>
      <label className='label'>Acceptance rate</label>
      <div className='rate'>{rate.toLocaleString(undefined, { style: 'percent' })}</div>
      <>
        <label title='Funds that were successfully processed by upstream'>Settled value</label>
        <div className='settled-amount'>
          <AmountWithCurrency amount={net_vol_by_settle_ccy.EUR} currency='EUR' />
        </div>
      </>
    </div>
  )
}

export default AcceptanceRate
