import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import checkIcon from '../assets/CheckIcon.svg'
import errorIcon from '../assets/ErrorIcon.svg'

const ToastMessage = ({ toastList }) => {
  const [list, setList] = useState(toastList)

  const deleteToast = (id) => {
    const index = list.findIndex((e) => e.id === id)
    list.splice(index, 1)
    setList([...list])
  }

  useEffect(() => {
    toastList.forEach((x) => {
      if (x?.title == 'Success') {
        ;(x.backgroundColor = '#5cb85c'), (x.icon = checkIcon)
      } else {
        ;(x.backgroundColor = '#d9534f'), (x.icon = errorIcon)
      }
    })
    setList(toastList)
    const interval = setInterval(() => {
      if (toastList.length && list.length) {
        deleteToast(toastList[0].id)
      }
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [toastList, list])

  return (
    <>
      <div className={`notification-container bottom-right`}>
        {list.map((toast, i) => (
          <div
            key={i}
            className={`notification toast bottom-right`}
            style={{ backgroundColor: toast.backgroundColor }}
          >
            <button onClick={() => deleteToast(toast.id)}>X</button>
            <div className='notification-image'>
              <img src={toast.icon} alt='' />
            </div>
            <div>
              <p className='notification-title'>{toast.title}</p>
              <p className='notification-message'>{toast.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

ToastMessage.propTypes = {
  toastList: PropTypes.array.isRequired,
}

export default ToastMessage
