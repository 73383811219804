import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

/**
 * ActiveStatus renders the status of anything based on an `active`
 * or `enabled` prop, i.e. Active or Suspended.
 * Handy to call in the style of:
 * <ActiveStatus {...merchant} />
 * <ActiveStatus {...user} />
 */
const ActiveStatus = ({ active, enabled }) => {
  const positive = active || enabled
  return (
    <span className={classnames({ status: true, active: positive, suspended: !positive })}>
      <span className='name'>{positive ? 'Active' : 'Suspended'}</span>
    </span>
  )
}
ActiveStatus.propTypes = {
  active: PropTypes.bool,
  enabled: PropTypes.bool,
}

export default ActiveStatus
