import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { isOperator } from '../../auth-roles'
import { useAuth } from '../../auth'

/** CountryFilter lets the user pick one or more countries. Very simple for now, text input. Needs
 * a better widget.
 */
const ReconFilter = ({ filter, setFilter }) => {
  // Track updates in local state for a smooth typing
  // experience.
  let reconStatus = filter.recon_status_any
  const history = useHistory()
  const { roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const reconStatuses = [
    {
      label: 'Undefined',
      value: 'undefined',
    },
    {
      label: 'Not Found',
      value: 'not-found',
    },
    {
      label: 'Mismatch [Status]',
      value: 'mismatch-status',
    },
    {
      label: 'Mismatch [Currency]',
      value: 'mismatch-currency',
    },
    {
      label: 'Mismatch [Amount]',
      value: 'mismatch-amount',
    },
    {
      label: 'Fully Matched',
      value: 'matched',
    },
  ]

  // Called when the value changes
  const changed = (e) => {
    setFilter({ ...filter, recon_status_any: e.target.value })
  }

  useEffect(() => {
    return history.listen((location) => {
      const params = new URLSearchParams(location.search)
      if (params.has('recon_status_any')) {
        reconStatus = params.get('recon_status_any')
      }
    })
  }, [history])

  return (
    <div className='filter'>
      <select value={reconStatus || ''} onChange={changed}>
        <option value=''>Any</option>
        {isUserOperator && (
          <>
            {reconStatuses.map((status, index) => (
              <option key={index} value={status.value}>
                {status.label}
              </option>
            ))}
          </>
        )}
      </select>
    </div>
  )
}

ReconFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  textEntryDebounceMs: PropTypes.number,
}

export default ReconFilter
