import { useState } from 'react'
import CurrentRules from './CurrentRules'
// import TestingRules from './TestRules'

const RoutingTabs = ({
  routingRules,
  setRoutingRules,
  routingRulesList,
  setRoutingRulesList,
  description,
  setDescription,
  errorHandler,
  setErrorHandler,
  state,
}) => {
  const [activeTab, setActiveTab] = useState('tab2')

  return (
    <div className='Tabs'>
      {/* Tab nav */}
      <ul className='nav'>
        {/* <li
          className={activeTab === 'tab1' ? 'active-item' : ''}
          onClick={() => setActiveTab('tab1')}
        >
          Rule Form Builder
        </li> */}
        <li
          className={activeTab === 'tab2' ? 'active-item' : ''}
          onClick={() => setActiveTab('tab2')}
        >
          Ruleset
        </li>
        {/* <li
          className={activeTab === 'tab3' ? 'active-item' : ''}
          onClick={() => setActiveTab('tab3')}
        >
          Test Scenario
        </li> */}
      </ul>
      <div className='outlet'>
        {/* {activeTab === 'tab1' ? <CurrentRules /> : null} */}
        {activeTab === 'tab2' ? (
          <CurrentRules
            routingRules={routingRules}
            setRoutingRules={setRoutingRules}
            routingRulesList={routingRulesList}
            setRoutingRulesList={setRoutingRulesList}
            description={description}
            setDescription={setDescription}
            errorHandler={errorHandler}
            setErrorHandler={setErrorHandler}
            state={state}
          />
        ) : null}
        {/* {activeTab === 'tab3' ? <TestingRules routingRules={routingRules} /> : null} */}
        {/* content will be shown here */}
      </div>
    </div>
  )
}
export default RoutingTabs
