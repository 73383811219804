import { toPairs, sortBy, path, reverse, compose } from 'ramda'
import classnames from 'classnames'
import { statusNames } from '.././txns/TxnStatus'

const StatusCount = ({ cnt_by_status = {} }) => {
  const toSortedCounts = compose(reverse, sortBy(path([1])), toPairs)
  if (!cnt_by_status) {
    return null
  }
  // Sort from most to least
  const counts = toSortedCounts(cnt_by_status.value)
  // Calc percentage of each
  // ...
  const countWithPer = counts.map(([status, count]) => [
    status,
    count,
    (1 / cnt_by_status.total) * count,
  ])

  // Don't render if there are no statuses
  if (counts.length === 0) {
    return null
  }

  return (
    <div>
      <label>Transactions by status:</label>
      {countWithPer.map(([status, count, percent]) => (
        <div key={status} className={classnames({ count: true, [status]: true, spacing: true })}>
          <span className='status'>{statusNames[status] || status}</span>
          <span className='percent'>{percent.toLocaleString(undefined, { style: 'percent' })}</span>
          <span className='val'>{count != null ? count.toLocaleString() : '-'}</span>
        </div>
      ))}
    </div>
  )
}

export default StatusCount
