import React from 'react'
import Header from './Header'
import Helmet from 'react-helmet'

const NotFound = () => {
  return (
    <section className='not-found'>
      <Header />
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <div className='content'>
        <div>
          <h1>Not found</h1>
          <p>The page you&apos;re looking for was not found.</p>
        </div>
      </div>
    </section>
  )
}

export default NotFound
