import { useEffect, useState } from 'react'
import Header from '../Header'
import Helmet from 'react-helmet'
import { useParams, Link } from 'react-router-dom'
import { getUser, enableUser, disableUser, assignUserRole, removeUserRole } from '../api'
import { useAuth } from '../auth'
import PropTypes from 'prop-types'
import UserDateTime from './UserDateTime'
import ActiveStatus from '../ActiveStatus'
import { useOrgScope, getOrgByType, OrgScopeRequired } from '../org-scope'
import { isOperator } from '../auth-roles'

/** ViewUser renders a single user. */
const ViewUser = () => {
  const { token, email, roles } = useAuth()
  const params = useParams()
  // User data
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [userInfo, setUserInfo] = useState()

  const isUserOperator = isOperator(roles)

  const fetchUser = async (id) => {
    if (!id) {
      return
    }
    setLoading(true)
    setFailed(false)
    try {
      const result = await getUser(token, id)
      setUserInfo(result)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchUser(params.id)
  }, [params.id])

  const { byID } = useOrgScope()

  return (
    <OrgScopeRequired>
      <section className='user'>
        <Header />
        <Helmet>
          <title>User {userInfo && userInfo.user ? userInfo.user.username : ''}</title>
        </Helmet>
        <div className='content'>
          {loading && !userInfo && <p className='loading'>Loading...</p>}
          {failed && (
            <p className='error'>
              Something went wrong - please try again, or <Link to='/support'>contact support</Link>
              .
            </p>
          )}
          {!failed && userInfo && (
            <div className='user'>
              <UserCore
                user={userInfo.user}
                stats={userInfo.stats}
                token={token}
                fetchUser={fetchUser}
                isUserOperator={isUserOperator}
              />
              <UserRoles
                token={token}
                user={userInfo}
                byID={byID}
                fetchUser={fetchUser}
                loggedInUser={email}
              />
              <AddScopes token={token} user={userInfo} byID={byID} fetchUser={fetchUser} />
            </div>
          )}
        </div>
      </section>
    </OrgScopeRequired>
  )
}

/** Core / basic user details */
const UserCore = ({ user, stats, token, isUserOperator, fetchUser }) => {
  const onEnable = async () => {
    await enableUser(token, user.id)
    fetchUser(user.id)
  }

  const onDisable = async () => {
    await disableUser(token, user.id)
    fetchUser(user.id)
  }

  return (
    <div className='user-core'>
      <h1>
        <i className='fas fa-user-alt' /> {user.username || '-'}
      </h1>
      <table className='user'>
        <tbody>
          <tr className='user_id'>
            <td className='key'>User ID</td>
            <td className='val user-table-val '>
              <span className='id'>{user.id}</span>
            </td>
          </tr>
          <tr className='user_id'>
            <td className='key'>Email</td>
            <td className='val user-table-val'>{user.username}</td>
          </tr>
          {user.created_at && (
            <tr className='created_at'>
              <td className='key'>Created at</td>
              <td className='val user-table-val '>
                <UserDateTime at={user.created_at} />
              </td>
            </tr>
          )}
          <tr className='status'>
            <td className='key'>Status</td>
            <td className='val user-table-val '>
              <ActiveStatus {...user} />
              {isUserOperator && user.enabled && (
                <button onClick={onDisable} className='user-enable-toggle-button'>
                  <i className='fas fa-ban' /> Suspend user account
                </button>
              )}
              {isUserOperator && !user.enabled && (
                <button onClick={onEnable} className='user-enable-toggle-button'>
                  <i className='fas fa-thumbs-up' />
                  Activate user account
                </button>
              )}
            </td>
          </tr>
          {stats && stats.last_login_at && (
            <tr className='last_login'>
              <td className='key'>Last login at</td>
              <td className='val user-table-val '>
                <UserDateTime at={stats.last_login_at} />
              </td>
            </tr>
          )}
          {stats && stats.successful_logins && (
            <tr className='successful_logins'>
              <td className='key'>No. success logins</td>
              <td className='val user-table-val '>{stats.successful_logins}</td>
            </tr>
          )}
          {stats && stats.failed_logins && (
            <tr className='failed_logins'>
              <td className='key'>No. failed logins</td>
              <td className='val user-table-val '>{stats.failed_logins}</td>
            </tr>
          )}
        </tbody>
      </table>
      <footer className='actions'></footer>
    </div>
  )
}

UserCore.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    entity_id: PropTypes.string,
    created_at: PropTypes.string,
    enabled: PropTypes.bool,
    username: PropTypes.string,
  }),
  stats: PropTypes.shape({
    last_login_at: PropTypes.string,
    successful_logins: PropTypes.number,
    failed_logins: PropTypes.number,
  }),
  token: PropTypes.string,
  isUserOperator: PropTypes.bool,
  fetchUser: PropTypes.func,
}

// Add scopes to the user
const AddScopes = ({ token, user, byID, fetchUser }) => {
  const [selected, setSelected] = useState('')
  const orgsByType = getOrgByType(byID)

  const userRoles = Object.hasOwn(user, 'roles') ? user.roles : []

  const buildBackOfficeRole = (id, type) => {
    switch (type) {
      case 'contract':
        type = 'ContractID'
        break
      case 'merchant':
        type = 'MerchantID'
        break
      case 'organisation':
        type = 'OrganisationID'
        break
      default:
        throw new Error('Unsupported org type encountered.')
    }

    return {
      r: 'back-office',
      t: type,
      id: id,
    }
  }

  const addScope = async (id) => {
    if (id !== '') {
      const org = byID[id]

      const role = buildBackOfficeRole(org.id, org.type)
      try {
        await assignUserRole(token, user.user.id, [role])
        fetchUser(user.user.id)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const organisations =
    Object.hasOwn(orgsByType, 'organisation') &&
    !userRoles.find((x) => x.r == 'back-office' && x.t == 'MerchantID')
      ? orgsByType['organisation'].filter((x) => !userRoles.find((y) => y.id == x.id))
      : []
  const contracts =
    Object.hasOwn(orgsByType, 'contract') &&
    !userRoles.find((x) => x.r == 'back-office' && x.t == 'MerchantID')
      ? orgsByType['contract'].filter((x) => !userRoles.find((y) => y.id == x.id))
      : []
  const merchants =
    Object.hasOwn(orgsByType, 'merchant') &&
    !userRoles.find((x) => x.r == 'back-office' && (x.t == 'ContractID' || x.t == 'OrganisationID'))
      ? orgsByType['merchant'].filter((x) => !userRoles.find((y) => y.id == x.id))
      : []

  return (
    <div className='user-add-scope'>
      <h3>Add Scope</h3>
      <select selected={selected} onChange={(e) => setSelected(e.target.value)}>
        <option>select a scope</option>
        {organisations.length > 0 ? (
          <optgroup label='Organisations'>
            {organisations.map((x) => (
              <option value={x.id} key={x.id}>
                {x.name}
              </option>
            ))}
          </optgroup>
        ) : null}
        {contracts.length > 0 ? (
          <optgroup label='Contracts'>
            {contracts.map((x) => (
              <option value={x.id} key={x.id}>
                {x.name}
              </option>
            ))}
          </optgroup>
        ) : null}
        {merchants.length > 0 ? (
          <optgroup label='Merchants'>
            {merchants.map((x) => (
              <option value={x.id} key={x.id}>
                {x.name}
              </option>
            ))}
          </optgroup>
        ) : null}
      </select>

      <button
        className='assign-role-action-button'
        disabled={selected === ''}
        onClick={() => addScope(selected)}
      >
        Add Scope
      </button>
    </div>
  )
}

// View / edit existing roles
const UserRoles = ({ token, user, byID, fetchUser }) => {
  const removeRoles = async (role) => {
    try {
      await removeUserRole(token, user.user.id, role)
      fetchUser(user.user.id)
    } catch (e) {
      console.log(e)
    }
  }

  const removeGlobalPermission = async (perm) => {
    const role = { r: perm }
    return removeRoles(role)
  }

  const addGlobalPermission = async (perm) => {
    const role = { r: perm }
    try {
      await assignUserRole(token, user.user.id, [role])
      fetchUser(user.user.id)
    } catch (error) {
      console.log(error)
    }
  }

  const updateScopedPermission = async (scope, perm, added) => {
    const role = scope
    if (!Object.hasOwn(role, 'p')) {
      role.p = []
    }
    if (added) {
      role.p.push(perm)
    } else {
      role.p = role.p.filter((x) => x === 'perm')
    }
    try {
      await assignUserRole(token, user.user.id, [role])
      fetchUser(user.user.id)
    } catch (error) {
      console.log(error)
    }
  }

  const scopePermissions = [
    {
      perm: 'controller',
      label: 'Controller',
      disabled: (scope) => {
        return scope.t == 'MerchantID'
      },
    },
    {
      perm: 'controller-no-merchant',
      label: 'Controller-No-Merchant',
      disabled: (scope) => {
        return !Object.hasOwn(scope, 'p') || !scope.p.find((x) => x == 'controller')
      },
    },
    {
      perm: 'gdpr',
      label: 'GDPR',
      disabled: (scope) => {
        return !Object.hasOwn(scope, 'p') || !scope.p.find((x) => x == 'controller')
      },
    },
    {
      perm: 'kyc',
      label: 'KYC',
      disabled: (scope) => {
        return !Object.hasOwn(scope, 'p') || !scope.p.find((x) => x == 'gdpr')
      },
    },
  ]

  const globalPermissions = [
    {
      perm: 'controller',
      label: 'Controller',
      disabled: !user.roles.find((x) => x.r == 'back-office' && x.t !== 'MerchantID'),
    },
    {
      perm: 'controller-implicit',
      label: 'Controller-implicit',
      disabled: !user.roles.find((x) => x.r == 'back-office' && x.t !== 'MerchantID'),
    },
    {
      perm: 'controller-no-merchant',
      label: 'Controller-No-Merchant',
      disabled: !user.roles.find((x) => x.r == 'controller' || x.r == 'controller-implicit'),
    },
    {
      perm: 'gdpr',
      label: 'GDPR',
      disabled: !user.roles.find((x) => x.r == 'controller' || x.r == 'controller-implicit'),
    },
    { perm: 'kyc', label: 'KYC', disabled: !user.roles.find((x) => x.r == 'gdpr') },
    {
      perm: 'operator',
      label: 'Operator',
      disabled: !user.user.username.endsWith('@innostream.co'),
    },
  ]

  const userScopes = user.roles.filter((x) => x.r == 'back-office')

  return (
    <div className='user-scopes'>
      <h3>Global Permissions</h3>
      <div>
        {globalPermissions.map((x) => (
          <label key={x.perm}>
            <input
              type='checkbox'
              disabled={x.disabled}
              checked={!!user.roles.find((r) => r.r == x.perm)}
              onChange={(e) => {
                if (e.target.checked) {
                  addGlobalPermission(x.perm)
                } else {
                  removeGlobalPermission(x.perm)
                }
              }}
            ></input>
            {x.label}
          </label>
        ))}
      </div>

      <h3 className='scope-heading'>Scopes</h3>
      {userScopes.length == 0 ? <p>User has no scopes.</p> : null}
      {userScopes.map((scope) => (
        <div className='user-scope' key={scope.id}>
          <div className='user-scope-header'>
            {`${scope.t} - ${scope.id} (${byID[scope.id].name})`}
            <div className='user-role-remove' onClick={() => removeRoles(scope)}>
              X
            </div>
          </div>
          <div>
            {scopePermissions.map((x) => (
              <label key={x.perm}>
                <input
                  type='checkbox'
                  checked={Object.hasOwn(scope, 'p') && !!scope.p.find((p) => p == x.perm)}
                  disabled={x.disabled(scope)}
                  onChange={(e) => {
                    updateScopedPermission(scope, x.perm, e.target.checked)
                  }}
                ></input>
                {x.label}
              </label>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ViewUser
