import Header from '.././Header'
import Helmet from 'react-helmet'
import { useEffect, useState } from 'react'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import { useAuth } from '../auth'
import ToggleButton from '../components/ToggleButton'
import { getAllSignicatThemes, saveSignicatTheme } from '../api'

const SignicatTheme = () => {
  const initialForm = {
    page_title: 'Signicat Theme',
    fav_icon: '',
    font_color: '#000000',
    buttons_color: '#59AFFF',
    buttons_hover_color: '#000000',
    buttons_text_color: '#F6FBFE',
    spinner_color: '#000000',
  }

  const [signicatThemeObject, setSignicatThemeObject] = useState(initialForm)
  const [themeName, setThemeName] = useState('')
  const [editMode, setEditMode] = useState(false)
  const [savedThemes, setSavedThemes] = useState()
  const { token } = useAuth()

  const handleObjectValueUpdate = (event) => {
    const { name, value } = event.target
    setSignicatThemeObject((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleDropdownSelection = (e) => {
    if (e.target.value) {
      const value = JSON.parse(e.target.value)
      setThemeName(value.name)
      setSignicatThemeObject((prevData) => ({
        ...prevData,
        page_title: value.page_title,
        fav_icon: value.fav_icon,
        font_color: value.font_color,
        buttons_color: value.buttons_color,
        buttons_hover_color: value.buttons_hover_color,
        buttons_text_color: value.buttons_text_color,
        spinner_color: value.spinner_color,
      }))
    }
  }

  const fileUpload = async (event) => {
    const { name, files } = event.target
    if (files.length > 0) {
      const reader = new FileReader()

      reader?.readAsDataURL(files[0])
      reader.onload = () => {
        setSignicatThemeObject((prevData) => ({ ...prevData, [name]: reader.result }))
      }
      reader.onerror = () => {
        setSignicatThemeObject((prevData) => ({ ...prevData, [name]: '' }))
      }
    } else {
      setSignicatThemeObject((prevData) => ({ ...prevData, [name]: '' }))
    }
  }

  const submit = async () => {
    const result = await saveSignicatTheme(token, themeName, signicatThemeObject)
    console.log(result)
    const themes = await getAllSignicatThemes(token)
    setSavedThemes(themes?.themes)
  }

  useEffect(async () => {
    const result = await getAllSignicatThemes(token)
    setSavedThemes(result?.themes)
  }, [])

  useEffect(() => {
    if (!editMode) {
      setSignicatThemeObject(initialForm)
    }
  }, [editMode])

  return (
    <section>
      <Header />
      <Helmet>
        <title>Signicat Theme</title>
      </Helmet>
      <div className='signicat-theme-component'>
        <div className='dropdown-container'>
          <h3>Edit Mode</h3>
          <ToggleButton handleChange={(e) => setEditMode(e.target.checked)} />
          {editMode && (
            <>
              <h3>Theme</h3>
              <select value={themeName.value} onChange={handleDropdownSelection}>
                <option value=''>Select Theme</option>
                {savedThemes?.map((theme, index) => (
                  // eslint-disable-next-line prettier/prettier
                <option key={index} value={JSON.stringify(theme)}>
                    {theme.name}
                  </option>
                ))}
              </select>
            </>
          )}
          {!editMode && (
            <>
              <h3>Theme Name</h3>
              <input
                value={themeName}
                onChange={(e) => setThemeName(e?.target?.value)}
                type='text'
              ></input>
            </>
          )}
        </div>
        <div className='main-container'>
          <div className='content-container'>
            <div className='signicat-theme-controls'>
              <h1 style={{ color: signicatThemeObject?.font_color }}>Open your new bank account</h1>
              <h4 style={{ color: signicatThemeObject?.font_color }}>
                It should take a few minutes
              </h4>
              <p style={{ color: signicatThemeObject?.font_color }}>
                Use your webcame or phone to photograph
              </p>
              <p style={{ color: signicatThemeObject?.font_color }}>1.) Your id document</p>
              <p style={{ color: signicatThemeObject?.font_color }}>2.) Your face</p>
              <div className='spinner-container'>
                <LoadingSpinner color={signicatThemeObject?.spinner_color} />
              </div>
              <button
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = signicatThemeObject?.buttons_hover_color
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = signicatThemeObject?.buttons_color
                }}
                style={{
                  color: signicatThemeObject?.buttons_text_color,
                  backgroundColor: signicatThemeObject?.buttons_color,
                }}
              >
                Submit
              </button>
            </div>
          </div>
          <div className='control-container'>
            <h1>Theme Settings</h1>
            <div>
              <p>Page Title</p>
              <input
                value={signicatThemeObject?.page_title}
                onChange={(e) => handleObjectValueUpdate(e)}
                type='text'
                name='page_title'
              ></input>
            </div>
            <div>
              <p>Font Color</p>
              <input
                value={signicatThemeObject?.font_color}
                onChange={(e) => handleObjectValueUpdate(e)}
                type='color'
                name='font_color'
              ></input>
            </div>
            <div>
              <p>Buttons Color</p>
              <input
                value={signicatThemeObject?.buttons_color}
                onChange={(e) => handleObjectValueUpdate(e)}
                type='color'
                name='buttons_color'
              ></input>
            </div>
            <div>
              <p>Buttons Hover Color</p>
              <input
                value={signicatThemeObject?.buttons_hover_color}
                onChange={(e) => handleObjectValueUpdate(e)}
                type='color'
                name='buttons_hover_color'
              ></input>
            </div>
            <div>
              <p>Buttons Text Color</p>
              <input
                value={signicatThemeObject?.buttons_text_color}
                onChange={(e) => handleObjectValueUpdate(e)}
                type='color'
                name='buttons_text_color'
              ></input>
            </div>
            <div>
              <p>Spinner Color</p>
              <input
                value={signicatThemeObject?.spinner_color}
                onChange={(e) => handleObjectValueUpdate(e)}
                type='color'
                name='spinner_color'
              ></input>
            </div>
            <div className='flex-container'>
              <p>Favicon</p>
              <div className='image-input-container'>
                <input type='file' onChange={fileUpload} name='fav_icon' />
                <div className='image-container'>
                  <img src={signicatThemeObject.fav_icon}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='submit-container'>
          <button onClick={submit}>Submit</button>
        </div>
      </div>
    </section>
  )
}

export default SignicatTheme
