import PropTypes from 'prop-types'
import { useAuth } from '../auth'
import { getKYCCSVUrl } from '../api'

/** Renders a widget that, given a `filter` specification, allows the current user to download
 * transactions matching the filter, using the user's auth token. */
const DownloadKYCCSVLink = ({ filter = {}, displayCount, owner }) => {
  const { token } = useAuth()
  let csvURL = getKYCCSVUrl(token, owner, { filter })
  if (displayCount === 0) {
    return null
  }
  return (
    <span className='download-csv'>
      <a href={csvURL} title='Download all transactions matching the current filter as CSV'>
        Download {displayCount != null && displayCount.toLocaleString()} as CSV
      </a>
    </span>
  )
}
DownloadKYCCSVLink.propTypes = {
  filter: PropTypes.object,
  displayCount: PropTypes.number,
  owner: PropTypes.string,
}
export default DownloadKYCCSVLink
