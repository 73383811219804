const ToggleButton = ({ toggleButtonStatus, handleChange, disabledState }) => {
  return (
    <div className='toggle-button'>
      <label className='switch'>
        <input
          type='checkbox'
          checked={toggleButtonStatus}
          onChange={handleChange}
          disabled={disabledState}
        />
        <span className='slider round'></span>
      </label>
    </div>
  )
}

export default ToggleButton
