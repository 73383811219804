import React, { useState } from 'react'
import MerchantDateTime from './MerchantDateTime'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { generateMerchantToken } from '../api'
import { useAuth } from '../auth'
import decodeJWT from 'jwt-decode'

// a simple component to render a merchants tokens
const MerchantTokens = ({ merchant_id, tokens }) => {
  const { token } = useAuth()
  const [req, setReq] = useState({ saving: false, failed: null, data: null })
  const onAddNew = async (test) => {
    try {
      setReq({ ...req, failed: null, saving: true, data: null })
      const data = await generateMerchantToken(token, merchant_id, test)
      setReq({ ...req, saving: false, data })
    } catch (failed) {
      setReq({ ...req, saving: false, failed })
    }
  }
  const tks = req?.data?.tokens || tokens
  return (
    <div className='merchant-tokens'>
      <h3>API Keys ({tks.length})</h3>
      <p>
        These are the API keys that this merchant may use to post transactions, as well as access
        related information. A merchant may have multiple active tokens at any one time, but expired
        tokens are no longer usable.
      </p>
      <table className='tokens'>
        <thead>
          <tr>
            <th className='num'>#</th>
            <th className='time'>
              <h6>Created</h6>
            </th>
            <th className='time'>
              <h6>Expires</h6>
            </th>
            <th className='purpose'>
              <h6>Purpose</h6>
            </th>
            <th className='by'>
              <h6>Created by user</h6>
            </th>
            <th className='token'>
              <h6>Token (ends with)</h6>
            </th>
          </tr>
        </thead>
        <tbody>
          {tks.map((token, i) => {
            const isTestToken = decodeJWT(token.token)?.test == true

            return (
              <tr className='merchant' key={i}>
                <td className='num'>{i + 1}.</td>
                <td className='time'>
                  <MerchantDateTime at={token.created_at} />
                </td>
                <td className='time'>
                  <MerchantDateTime at={token.expires_at} />
                </td>
                <td className='purpose'>
                  {isTestToken ? 'test' : 'production' }
                </td>
                <td className='by'>
                  <Link to={`/user/${token.by}`}>{token.by}</Link>
                </td>
                <td className='token'>
                  <Token token={token} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <footer className='actions'>
        {!req.saving && !req.data && (
          <button onClick={() => onAddNew(true)}>
            <i className='fas fa-plus' /> Add new test key
          </button>
        )}
        {!req.saving && !req.data && (
          <button onClick={() => onAddNew(false)}>
            <i className='fas fa-plus' /> Add new production key
          </button>
        )}        
        {req.saving && <span className='loading'>Generating new key...</span>}
        {req.failed && <span className='error'>Something went wrong - please try again</span>}
        {!req.saving && req.data && (
          <span className='success'>
            <i className='fas fa-check' /> New key generated
          </span>
        )}
      </footer>
    </div>
  )
}

MerchantTokens.propTypes = {
  merchant_id: PropTypes.string.isRequired,
  tokens: PropTypes.arrayOf(
    PropTypes.shape({
      token: PropTypes.string,
      subject: PropTypes.string,
      created_at: PropTypes.string,
      expires_at: PropTypes.string,
      by: PropTypes.string,
    })
  ),
}

const Token = ({ token }) => {
  const [copied, setCopied] = useState(false)

  const copy = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 3000)
  }

  const last10 =
    token.token && token.token.length > 10
      ? `...${token.token.slice(token.token.length - 10)}`
      : token.token
  return (
    <>
      <span className='token'>{last10}</span>
      <CopyToClipboard text={token.token} onCopy={() => copy()} title={'Copy token'}>
        <button>
          {' '}
          {copied ? (
            <>
              <i className='fas fa-check' /> Copied
            </>
          ) : (
            <>
              <i className='fas fa-copy' /> Copy
            </>
          )}
        </button>
      </CopyToClipboard>
    </>
  )
}

Token.propTypes = {
  token: PropTypes.shape({
    token: PropTypes.string,
    subject: PropTypes.string,
    created_at: PropTypes.string,
    expires_at: PropTypes.string,
    by: PropTypes.string,
  }),
}

export default MerchantTokens
