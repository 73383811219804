import { has, any, toPairs } from 'ramda'

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user is an operator (which is our admin)
export const isOperator = (roles = {}) => has('operator', roles)

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user is an controller (e.g. Celoxo)
export const isController = (roles = {}) => has('controller', roles)

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user is an controller (e.g. Celoxo)
export const isControllerNoMerchant = (roles = {}) => has('controller-no-merchant', roles)

export const hideMerchants = (roles = {}) =>
  isControllerNoMerchant(roles) && roles['back-office']?.length < 2

export const maskMerchants = (roles = {}) =>
  isControllerNoMerchant(roles) && roles['back-office']?.length > 1

// for a users roles (roles:{roleName -> contexts}) determine if this user has the GDPR role
export const isGDPR = (roles = {}) => has('gdpr', roles)

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user has the KYC role
export const isKYC = (roles = {}) => has('kyc', roles)

// Utility: Checks if the user has any 'contract' roles
export const hasContextContractID = (roles = {}) =>
  roles != null && any((pair) => any(({ t }) => t == 'ContractID', pair[1]), toPairs(roles))
