import {
  BarChart,
  Bar,
  Brush,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts'
import LoadingSpinner from '../../loading-spinner/loading-spinner'
import { assocPath } from 'ramda'
import { useTheme } from '../../theme'
import PropTypes from 'prop-types'

const CountryStatsBarChart = ({
  countryStats,
  countryStatsFailed,
  countryStatsLoading,
  title,
  filter,
  setFilter,
}) => {
  const theme = useTheme()
  const isMobile = window.matchMedia('(max-width: 420px)').matches

  const onBarClick = (e) => {
    setFilter(assocPath(['country_any'], e.country, filter))
  }

  const renderTooltipContent = (o) => {
    const { payload, label } = o
    return (
      <div className='customized-tooltip-content'>
        <p>{label}</p>
        <p>{`Failed: ${payload[0]?.payload.failed}`}</p>
        <p>{`Pending: ${payload[0]?.payload.pending}`}</p>
        <p>{`Ok: ${payload[0]?.payload.ok}`}</p>
        <p>{`Abandoned: ${payload[0]?.payload.abandoned}`}</p>
        <p>{`AR: ${(payload[0]?.payload.ar * 100).toFixed(1)}`}</p>
      </div>
    )
  }

  return countryStats?.length && !countryStatsFailed && !countryStatsLoading ? (
    <div className='stacked-bar-chart-wrapper'>
      <div className='graph-title-container'>
        <span className='table-title'>{title}</span>
      </div>
      <ResponsiveContainer>
        <BarChart layout='vertical' data={countryStats}>
          <CartesianGrid />
          <XAxis type='number' />
          <YAxis type='category' dataKey='country' interval={0} hide={isMobile} />
          <Tooltip content={renderTooltipContent} />
          <Legend verticalAlign='bottom' wrapperStyle={{ lineHeight: '40px' }} />
          <Brush
            travellerWidth={10}
            startIndex={0}
            endIndex={isMobile ? 15 : countryStats.length - 1}
            dataKey='country'
            height={20}
            stroke={theme?.graphColours?.ok}
            className='theme-brush'
          />
          <Bar dataKey='ok' stackId='a' fill={theme?.graphColours?.ok} onClick={onBarClick} />
          <Bar
            dataKey='failed'
            stackId='a'
            fill={theme?.graphColours?.failed}
            onClick={onBarClick}
          />
          <Bar
            dataKey='pending'
            stackId='a'
            fill={theme?.graphColours?.pending}
            onClick={onBarClick}
          />
          {isMobile && (
            <Bar dataKey='' stackId='d' fill='#fff'>
              <LabelList dataKey='country' position='insideLeft' />
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : countryStatsLoading ? (
    <div className='loading-spinner-wrapper'>
      <LoadingSpinner />
    </div>
  ) : countryStatsLoading ? (
    <div className='loading-spinner-wrapper'>
      <div>
        <LoadingSpinner color='red' />
        <div>could not retrieve data</div>
      </div>
    </div>
  ) : !countryStats?.length && !countryStatsFailed && !countryStatsLoading ? (
    <div className='stacked-bar-chart-wrapper'>
      <span className='table-title'>{title}</span>
      <div className='no-data-dashboard'>
        <h1>NO DATA</h1>
      </div>
      <ResponsiveContainer>
        <BarChart data={[{ name: 1000 }, { name: 2000 }, { name: 3000 }]}>
          <CartesianGrid />
          <XAxis dataKey='name' type='number' />
          <YAxis hide={isMobile} />
          <Brush
            travellerWidth={10}
            startIndex={0}
            endIndex={2}
            dataKey='name'
            height={20}
            stroke='#D3D3D3'
          />
          <Legend verticalAlign='bottom' wrapperStyle={{ lineHeight: '40px' }} />
          <Bar stackId='a' fill='#D3D3D3' />
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : null
}

CountryStatsBarChart.propTypes = {
  countryStats: PropTypes.array,
  countryStatsFailed: PropTypes.bool,
  countryStatsLoading: PropTypes.bool,
  title: PropTypes.string,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
}

export default CountryStatsBarChart
