import React from 'react'
import { Link } from 'react-router-dom'

/** Tells a user how to obtain an account (no self-service signup) */
const RequestAccount = () => (
  <section className='request-account'>
    <div className='content'>
      <h1>Request user account</h1>
      <p>
        Please send us an email indicating whether you are affiliated with one of our existing
        customers, or whether you wish to evaluate cosmonaut for your own business, at:
      </p>
      <p className='contact-email'>
        <a href='mailto:accounts@cosmo.tech'>accounts@cosmo.tech</a>
      </p>
      <footer className='actions'>
        <Link to='/sign-in'>Sign in to existing account</Link>
      </footer>
    </div>
  </section>
)

export default RequestAccount
