import PropTypes from 'prop-types'
import { path } from 'ramda'
import { renderStatusName } from '../TxnStatus.jsx'
import { majorErrorCodes } from '../error-codes'

// All non-failed statuses - failed are handled in their own section with status codes etc
const statuses = ['ok', 'cust_verification_required', 'pending', 'refunded', 'charged_back']
const statusNames = {
  cust_verification_required: 'verification pending (3DS, KYC)',
}

const StatusFilter = ({ filter, setFilter }) => {
  // Quick simple version to filter based on one status
  const status = path(['status_any', 0], filter)
  const errorCode = filter?.error_code_major
  // Composite 'value' for select, since we're rolling two values (status, and potentially error code)
  // into this one control.
  const selectVal = status != null ? (errorCode != null ? `${status}:${errorCode}` : status) : null
  const changed = (e) => {
    const newStatus = e.target.value
    // Value could be single or composite, e.g. 'ok' or 'failed:6'
    const [status, error_code_major] = newStatus.split(':')
    setFilter({ ...filter, status_any: status ? [status] : [], error_code_major })
  }
  const errorCodes = Array.from(majorErrorCodes.keys()).sort((a, b) => a - b) // Numeric sort
  return (
    <div className='filter status'>
      <select value={selectVal || ''} onChange={changed}>
        <option value=''>Any status</option>
        {statuses.map((s) => (
          <option key={s} value={s} className={s}>
            {statusNames[s] || renderStatusName(s)}
          </option>
        ))}
        <option value='failed:22' className='abandoned'>
          abandoned
        </option>
        <option value='failed' className='failed'>
          failed (any reason)
        </option>
        {errorCodes.map((code) => (
          <option key={code} value={`failed:${code}`} className='failed'>{`failed (${code} - ${
            majorErrorCodes.get(code) || '(no description)'
          })`}</option>
        ))}
      </select>
    </div>
  )
}

StatusFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default StatusFilter
