import React, { useEffect, useState } from 'react'
import {
  getContracts,
  getMerchants,
  linkMerchantAsPartOfAnotherMerchant,
  linkMerchantAsPartOfParentContract,
} from '../api'
import { useAuth } from '../auth'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { parseMerchantFilters, updateSearchParamsWithFilters } from './filters'

/**
 * LinkMerchantToParent a component that will link a merchant to a parent (either a contract or another merchant)
 */
const LinkMerchantToParent = ({ merchant, onSuccess, onCancel }) => {
  const { token } = useAuth()
  // All accessible merchants
  const [merchants, setMerchants] = useState([])
  const [loadingMerchants, setLoadingMerchants] = useState(false)
  const [failedMerchants, setFailedMerchants] = useState()
  // All accessible contracts
  const [contracts, setContracts] = useState([])
  const [loadingContracts, setLoadingContracts] = useState(false)
  const [failedContracts, setFailedContracts] = useState()
  // The selected parent
  const [parent, setParent] = useState()
  const [linkParentLoading, setLinkParentLoading] = useState()
  const [failedLinkParent, setFailedLinkParent] = useState()

  // Are we setting a merchant or a contract as a parent
  const [linkType, setLinkType] = useState('')
  const urlParams = new URLSearchParams(location.search)
  const filter = parseMerchantFilters(urlParams)
  updateSearchParamsWithFilters(urlParams, filter)

  // Fetch accessible merchants from the back-end
  const fetchMerchants = async () => {
    setLoadingMerchants(true)
    setFailedMerchants(false)
    try {
      filter.count = 200
      const resp = await getMerchants(token, { filter })

      if (resp) {
        //Sorting
        resp.results.sort(function (a, b) {
          let textA = a.registered_name.toUpperCase()
          let textB = b.registered_name.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
        setMerchants(resp.results)
      }
      setLoadingMerchants(false)
    } catch (failed) {
      setFailedMerchants(failed)
      setLoadingMerchants(false)
    }
  }
  useEffect(() => {
    fetchMerchants()
  }, [])

  // Fetch contracts from the back-end
  const fetchContracts = async () => {
    setLoadingContracts(true)
    setFailedContracts(false)
    try {
      const result = await getContracts(token)
      result.sort(function (a, b) {
        let textA = a.description.toUpperCase()
        let textB = b.description.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      setContracts(result)
      setLoadingContracts(false)
    } catch (failed) {
      setLoadingContracts(false)
      setFailedContracts(failed)
    }
  }
  useEffect(() => {
    fetchContracts()
  }, [])

  const onLinkAsPartOf = async () => {
    setFailedLinkParent(null)
    setLinkParentLoading(true)
    try {
      if (linkType === 'merchant') {
        await linkMerchantAsPartOfAnotherMerchant(token, merchant.id, parent)
      } else if (linkType === 'contract') {
        await linkMerchantAsPartOfParentContract(token, merchant.id, parent)
      }
      setLinkParentLoading(false)
      onSuccess()
    } catch (e) {
      setLinkParentLoading(false)
      setFailedLinkParent(`There was a problem linking this merchant ${e}`)
    }
  }

  const setIDOfParent = (value, type) => {
    if(type == 'contract'){
      setParent(contracts.find(contract => contract.description == value).id)
    } else {
      setParent(merchants.find(merchant => merchant.registered_name == value).id)
    }
  }

  return (
    <div className='link-parent'>
      <h3>Link {merchant.registered_name || '(un-named merchant)'} to a parent organisation</h3>
      <div className='content'>
        {(loadingMerchants || loadingContracts) && <p className='loading'>Loading data...</p>}
        {linkParentLoading && <p className='loading'>Updating...</p>}
        {(failedLinkParent || failedMerchants || failedContracts) && (
          <p className='error'>
            {failedLinkParent && failedLinkParent.code ? (
              <span>Failed with code: {failedLinkParent && failedLinkParent.code}</span>
            ) : (
              <span>
                Something went wrong - please try again, or{' '}
                <Link to='/support'>contact support</Link>
              </span>
            )}
          </p>
        )}

        <p>
          <input className='datalist-input' list="contract-type"  placeholder="Select Type" onChange={(e) => setLinkType(e.target.value.toLowerCase())} onClick={(e) => e.target.value = ''}/>
          <datalist id="contract-type">
            <option value='Contract'>Contract</option>
            <option value='Merchant'>Merchant</option>
          </datalist>
        </p>

        <p>
          {linkType && linkType === 'contract' && (
            <>
            <input className='datalist-input' list="contracts"  placeholder="Select Contract" onChange={(e) => setIDOfParent(e.target.value, "contract")} onClick={(e) => e.target.value = ''}/>
            <datalist id="contracts">
              {contracts.map((contract, idx) => (
                <option key={idx}>
                  {contract.description}
                </option>
              ))}
            </datalist>
            </>
          )}
          {linkType && linkType === 'merchant' && (
            <>
              <input className='datalist-input' list="merchants"  placeholder="Select Merchant" onChange={(e) => setIDOfParent(e.target.value, "merchant")} onClick={(e) => e.target.value = ''}/>
              <datalist id="merchants">
                {merchants.map((merchant, idx) => (
                  <option key={idx}>
                    {merchant.registered_name}
                  </option>
                ))}
              </datalist>
            </>
          )}
        </p>
      </div>
      <p className='warning'>
        <em>
          <strong>WARNING:</strong> Linking a merchant to a parent means the parent will be able to
          see all of this merchant&apos;s transactions
        </em>
      </p>
      <footer className='actions'>
        <button onClick={onLinkAsPartOf} disabled={!parent}>
          Link up
        </button>
        <button onClick={onCancel}>Cancel</button>
      </footer>
    </div>
  )
}

LinkMerchantToParent.propTypes = {
  // The merchant object
  merchant: PropTypes.object.isRequired,
  // Success callback handler
  onSuccess: PropTypes.func,
  // Cancel callback handler
  onCancel: PropTypes.func,
}

export default LinkMerchantToParent
