import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const rendering = {
  a: {
    icon: 'fas fa-check',
    text: 'Allow',
  },
  d: {
    icon: 'fas fa-ban',
    text: 'Deny',
  },
  unknown: {
    icon: 'fas fa-question',
    text: 'Unset',
  },
}

/* Given a `choice` for an allow/deny entry, renders it visually. */
const Choice = ({ choice }) => (
  <span className={classnames({ choice: true, unknown: !choice, [choice]: choice })}>
    {rendering[choice]?.text || rendering.unknown.text}{' '}
    <i className={rendering[choice]?.icon || rendering.unknown.icon} />
  </span>
)

Choice.propTypes = {
  choice: PropTypes.oneOf(['a', 'd']),
}

export default Choice
