import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Brush,
  LabelList,
} from 'recharts'
import LoadingSpinner from '../../loading-spinner/loading-spinner'
import { assocPath } from 'ramda'
import TopRouteExcelDownload from '../excel-templates/TopRouteGraphExcel'
import { useTheme } from '../../theme'
import PropTypes from 'prop-types'

const StackedBarChart = ({
  binStats,
  binsFailed,
  binsLoading,
  title,
  filter,
  setFilter,
  keyLabel,
}) => {
  const theme = useTheme()
  if (binStats?.length) {
    Math.max.apply(
      Math,
      binStats.map(function (o) {
        return o.total
      })
    )
  }

  const isMobile = window.matchMedia('(max-width: 420px)').matches

  const renderTooltipContent = (o) => {
    const { payload, label } = o

    return (
      <div className='customized-tooltip-content'>
        {label != '' && <p>{`${keyLabel}: ${label}`}</p>}
        {payload[0]?.payload?.rule && <p>{`Rule: ${payload[0]?.payload?.rule}`}</p>}
        {payload[0]?.payload.merchant_name && (
          <p>{`Merchant Name: ${payload[0]?.payload.merchant_name}`}</p>
        )}
        <p>{`Total: ${payload[0]?.payload.total}`}</p>
        <p>{`Failed: ${payload[0]?.payload.failed}`}</p>
        <p>{`Ok: ${payload[0]?.payload.ok}`}</p>
        <p>{`Abandoned: ${payload[0]?.payload.abandoned}`}</p>
        <p>{`Pending: ${payload[0]?.payload.pending}`}</p>
        <p>{`Total Settled Amount (EUR): ${Math.trunc(payload[0]?.payload.sum_Settled_Amount_EUR)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</p>
        <p>{`AR: ${(payload[0]?.payload.acceptance_rate * 100).toFixed(1)}`}</p>
        {payload[0]?.payload?.issuer && <p>{`Issuer: ${payload[0]?.payload?.issuer}`}</p>}
      </div>
    )
  }

  const onBarClick = (e) => {
    if (keyLabel == 'Rule') {
      setFilter(assocPath(['rule'], e.rule, filter))
    } else {
      setFilter(assocPath(['card_cnts'], e.bin, filter))
    }
  }

  return binStats?.length && !binsFailed && !binsLoading ? (
    <div className='stacked-bar-chart-wrapper'>
      {!isMobile && keyLabel == 'Rule' && <TopRouteExcelDownload data={binStats} />}
      <div className='graph-title-container'>
        <span className='table-title'>{title}</span>
      </div>
      <ResponsiveContainer>
        <BarChart layout='vertical' data={binStats}>
          <CartesianGrid />
          <XAxis type='number' />
          <YAxis
            dataKey='bin'
            type='category'
            interval={0}
            yAxisId={0}
            hide={keyLabel == 'Rule' || keyLabel == 'MerchantName' || isMobile}
          />
          <Tooltip content={renderTooltipContent} />
          <Legend verticalAlign='bottom' wrapperStyle={{ lineHeight: '40px' }} />
          <Brush
            travellerWidth={10}
            startIndex={0}
            endIndex={isMobile ? 15 : binStats.length - 1}
            dataKey='country'
            height={20}
            stroke={theme?.graphColours?.ok}
            className='theme-brush'
          />
          <Bar dataKey='ok' stackId='a' fill={theme?.graphColours?.ok} onClick={onBarClick} />
          <Bar
            dataKey='failed'
            stackId='a'
            fill={theme?.graphColours?.failed}
            onClick={onBarClick}
          />
          <Bar
            dataKey='pending'
            stackId='a'
            fill={theme?.graphColours?.pending}
            onClick={onBarClick}
          />
          {isMobile && keyLabel == 'BIN' && (
            <Bar dataKey='' stackId='d' fill='#fff'>
              <LabelList dataKey='bin' position='insideLeft' />
            </Bar>
          )}
          {keyLabel == 'Rule' && (
            <Bar dataKey='' stackId='d' fill='#fff'>
              <LabelList dataKey='rule' position='insideLeft' />
            </Bar>
          )}
          {keyLabel == 'MerchantName' && (
            <Bar dataKey='' stackId='d' fill='#fff'>
              <LabelList dataKey='merchant_name' position='insideLeft' />
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : binsLoading ? (
    <div className='loading-spinner-wrapper'>
      <LoadingSpinner />
    </div>
  ) : binsFailed ? (
    <div className='loading-spinner-wrapper'>
      <div>
        <LoadingSpinner color='red' />
        <div>could not retrieve data</div>
      </div>
    </div>
  ) : !binStats?.length && !binsFailed && !binsLoading ? (
    <div className='stacked-bar-chart-wrapper'>
      <span className='table-title'>{title}</span>
      <div className='no-data-dashboard'>
        <h1>NO DATA</h1>
      </div>
      <ResponsiveContainer>
        <BarChart data={[{ name: 1000 }, { name: 2000 }, { name: 3000 }]}>
          <CartesianGrid />
          <XAxis dataKey='name' type='number' />
          <YAxis hide={isMobile} />
          <Brush
            travellerWidth={10}
            startIndex={0}
            endIndex={2}
            dataKey='name'
            height={20}
            stroke='#D3D3D3'
            className='theme-brush'
          />
          <Legend verticalAlign='bottom' wrapperStyle={{ lineHeight: '40px' }} />
          <Bar stackId='a' fill='#D3D3D3' />
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : null
}

StackedBarChart.propTypes = {
  binStats: PropTypes.array,
  binsFailed: PropTypes.bool,
  binsLoading: PropTypes.bool,
  title: PropTypes.string,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  keyLabel: PropTypes.string,
}

export default StackedBarChart
