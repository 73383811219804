import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import { assocPath } from 'ramda'
import { useHistory } from 'react-router-dom'

const GenericTextFilter = ({
  filter,
  filterName,
  placeholder,
  setFilter,
  textEntryDebounceMs = 1000,
}) => {
  const [filterValue, setFilterValue] = useState(filter[filterName])

  const updateParentFilter = () => {
    setFilter(assocPath([filterName], filterValue, filter))
  }

  const history = useHistory()

  const updateParentFilterDebounced = useDebouncedCallback(updateParentFilter, textEntryDebounceMs)

  const changed = (e) => {
    setFilterValue(e.target.value)
    updateParentFilterDebounced.callback()
  }

  const keyPressed = (e) => {
    if (e.key === 'Enter') {
      updateParentFilter()
    }
  }

  useEffect(() => {
    return history.listen((location) => { 
      const params = new URLSearchParams(location.search)
      if(params.has(filterName)){
        setFilterValue(params.get(filterName))
      }
    }) 
  },[history])

  return (
    <div className='filter card'>
      <input
        className='full-width'
        type='text'
        value={filterValue || ''}
        onKeyPress={keyPressed}
        onChange={changed}
        placeholder={placeholder}
      />
    </div>
  )
}

export default GenericTextFilter
