import { useEffect, useRef } from 'react'

/** A custom React hook, inspired by a blog post by Dan Abramov,
 * to execute a side effect ('callback') at a particular frequency,
 * with automatic cleanup when the component using this hook unmounts.
 */
export const useInterval = (callback, delay, runImmediate = true) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (delay !== null) {
      if (runImmediate) {
        tick()
      }
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
